/* NOTE - these should be phased out in favor of TailwindCSS variations */

.bg-navy {
    background-color: #011e69;
}

.text-navy {
    color: #011e69;
}

.border-navy {
    border: #011e69 1px solid;
}

.bg-background-main {
    background-color: #f5f6f9;
}

.text-background-main {
    color: #f5f6f9;
}

.border-background-main {
    border: #f5f6f9 1px solid;
}

.bg-primary {
    background-color: #005bc0;
}

.text-primary {
    color: #005bc0;
}

.border-primary {
    border: #005bc0 1px solid;
}

.bg-primary-dark {
    background-color: #004fa7;
}

.text-primary-dark {
    color: #004fa7;
}

.border-primary-dark {
    border: #004fa7 1px solid;
}

.bg-primary-light {
    background-color: #0067da;
}

.text-primary-light {
    color: #0067da;
}

.border-primary-light {
    border: #0067da 1px solid;
}

.bg-secondary {
    background-color: #ffc257;
}

.text-secondary {
    color: #ffc257;
}

.border-secondary {
    border: #ffc257 1px solid;
}

.bg-tertiary {
    background-color: #1b85eb;
}

.text-tertiary {
    color: #1b85eb;
}

.border-tertiary {
    border: #1b85eb 1px solid;
}

.bg-alert {
    background-color: #ee483b;
}

.text-alert {
    color: #ee483b;
}

.border-alert {
    border: #ee483b 1px solid;
}

.bg-success {
    background-color: #2dc8a8;
}

.text-success {
    color: #2dc8a8;
}

.border-success {
    border: #2dc8a8 1px solid;
}

.bg-updated-black {
    background-color: #1b1c1d;
}

.text-updated-black {
    color: #1b1c1d;
}

.border-updated-black {
    border: #1b1c1d 1px solid;
}

.bg-grey-mid {
    background-color: #5f6060;
}

.text-grey-mid {
    color: #5f6060;
}

.border-grey-mid {
    border: #5f6060 1px solid;
}

.bg-grey-dim {
    background-color: #bababb;
}

.text-grey-dim {
    color: #bababb;
}

.border-grey-dim {
    border: #bababb 1px solid;
}

.bg-grey-border {
    background-color: #e8e8e8;
}

.text-grey-border {
    color: #e8e8e8;
}

.border-grey-border {
    border: #e8e8e8 1px solid;
}
