/* IMPORTANT - this is *not* a .module.css file because the .SingleDatePicker CSS names */
/* are globablly defined by the react-dates library. This file must be imported globally */
/* in both the Next.js app and in Storybook for the Datepicker override CSS to work correctly */

/* ResizableTableHeaderCell styles */
/* NOTE - the `transform: none` rule here is IMPORTANT. */
/* We define this rule to override the default CSS styles applied by the React RnD library we're using */
/* beacuse the default styles conflict with the way we render tooltips in the TableHeaderCell component */
/* By default, React RnD defines a `transform` CSS property in-line - this has a cascading effect of creating a new */
/* coordinate system _local_ to the ResizableTableHeaderCell, which prevents our `position: fixed` tooltip from rendering */
/* in the correct position because it's using the wrong coordinate system as basis for calculating position. */
.resizableTableHeaderCell {
    border-left: 1px solid #dfdfdf;
    transform: none !important;
    /* Ensures the last ResizableTableHeaderCell has a right-border */
}
.resizableTableHeaderCell:not(.stickyLeftResizableHeaderCell) {
    position: inherit !important;
}
.resizableTableHeaderCell:first-child {
    border-left: none;
}
.resizableTableHeaderCell:nth-last-of-type(2) {
    border-right: 1px solid #dfdfdf !important;
    /* Moves the resize handle in by 1px to compensate for the 1px border-right */
}
.resizableTableHeaderCell:nth-last-of-type(2) .resizeHandle {
    right: 1px !important;
}

/* Defines styles for ResizableTableCell with StickyLeftResizableHeaderCell column feature */
.stickyLeftResizableHeaderCell {
    position: sticky !important;
    left: 0 !important;
    z-index: 100;
    border-right: 1px solid #dfdfdf;
    box-shadow: 4px 0 3px 0px rgba(0, 0, 0, 0.05);
}
/* Targets the next sibling to the .stickyLeft element */
/* This is done so the box-shadow above doesn't */
/* create visual noise with the left-border on the adjacent cell */
.stickyLeftResizableHeaderCell + .resizableTableHeaderCell {
    border-left: none;
}

/* Defines styles for ScrollableTableBody.tableHeaderClassName */
/* Used to apply special border-radius rules for the TableHeader to match the border-radius of the AdvancedFiltersTable */
/* NOTE - the 1px additions here are to compensate for the 1px border around the table header */
.headerRowHeightDefault {
    border-top-left-radius: calc(0.75rem + 1px);
    border-top-right-radius: calc(0.75rem + 1px);
}

.headerRowHeightLarge {
    border-top-left-radius: calc(1rem + 1px);
    border-top-right-radius: calc(1rem + 1px);
}
