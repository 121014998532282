.header {
    color: #bdbdbd; /* $medium-grey */
    font-size: 12px; /* $inputPadding */
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
}

.headerWrapper {
    padding: 0 15px; /* $defaultItemPadding */
}

.carret {
    padding-left: 5px; /* $smallPadding */
}

.emptySearchSvg {
    width: 85px;
    height: 85px;
    margin: 0 auto;
}
.emptySearchSvg svg {
    width: 85px;
    height: 85px;
}

.emptyHeader {
    color: #1b85eb; /* $tertiary */
    font-family: "Avenir", sans-serif; /* $secFont */
    font-size: 24px; /* $amountFontSize */
    font-weight: 700; /* $xHeavyWeight */
    line-height: 33px;
    text-align: center;
}

.emptyMessage {
    font-family: "Avenir", sans-serif;
    line-height: 19px;
    text-align: center;
}
