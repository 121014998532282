/* * * * */
/* ColumnListHeader */
.headerBody {
    border-bottom: 1px solid #ededed; /* $background-border-grey */
    position: sticky;
    top: 0;
}

/* * * * */
/* ColumnListItem */
.columnListItem {
    border-bottom: 1px solid #ededed; /* $background-border-grey */
}
.columnListItem:hover {
    background: #5f6060; /* $greyMid */
}

.columnListItemDragging {
    border-bottom: 1px solid #ededed; /* $background-border-grey */
}

/* * * * */
/* ColumnListItemDragHandle */
.dragHandleWrapper {
    cursor: grab;
}

.dragHandlePlaceholder {
    visibility: hidden;
}

.dragHandleRow {
    margin-bottom: 4px;
}
.dragHandleRow:last-child {
    margin-bottom: 0;
}

.dragHandleDot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #dfdfdf; /* $lighter-grey */
}
.dragHandleDot:first-child {
    margin-right: 4px;
}

/* * * * */
/* ColumnListItemCheckbox */
.visibleCheckbox {
    outline: none;
    border: none;
    background: transparent;
}

/* * * * */
/* ManageColumnsModal */
.manageColumnsModal {
    width: 600px !important;
}
