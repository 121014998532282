.sidebarWrapper {
    width: 220px;
    min-width: 220px;
    border: 1px solid #dfdfdf;
    border-width: 0 1px;
    background: #fff;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    /* NOTE - this z-index rule is here to override the z-index provided by the <StickyTop> component */
    /* It's mainly here to account for an edge-case that crops up when the <PlanTrial> banner component */
    /* is also displayed on the page causing the two elements to scroll ontop of eachother */
    z-index: 95 !important;
}

.sidebarContent {
    padding: 20px 0;
}

.navBodyFullWidth {
    overflow: auto;
    max-height: 100vh;
}

.groupHeader {
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.link {
    height: 30px;
    line-height: 18px; /* this line-height was chosen manually so that top/bottom paddings are even */
    text-decoration: none;
    font-family: "Open Sans";
    font-weight: 400;
    color: #1b1c1d;
}
.link:hover {
    color: #1b1c1d;
    background: #f5f6f9;
    text-decoration: none;
}

.linkActive {
    color: #005bc0;
    padding-left: 5px;
    background: #f5f6f9;
    font-family: "Open Sans";
    font-weight: 600;
}
.linkActive:hover {
    color: #005bc0;
    text-decoration: none;
}

/* using this hard-coded value allows to align link group headers more precisely than using `.col-2` class for icon wrappers */
.iconWrapper {
    width: 26px;
}
