.teams {
    /* we are using z-index here so that team circles don't overlap other items with lower z-index values. it works here because the element is inside a flex-container */
    z-index: 1;
}

.nameCircle {
    /* This margin is the default spacing between every team circle. */
    /* The margin is being override by the above nth-last-child statement when */
    /* there are more than $maximumSpacedTeams in the teams ul container */
    /* If there are less than the maximum teams, we space between them; otherwise, */
    /* we ovelap them on each other */
    margin: 0 5px 0 0;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: bold;
}
.nameCircle:nth-last-child(n + 5) ~ li,
.nameCircle:nth-last-child(n + 5):first-child {
    margin: 0 0 0 -8px;
}

.teamNameTooltip {
    background: #fff;
    border-radius: 5px;
    text-align: center;
    color: #5a5a5a;
    /* $black */
    font-weight: 400;
    white-space: nowrap;
}
