/* Application Fonts */
@font-face {
    font-family: "Avenir-Roman";
    src: url("/static/fonts/Avenir-Roman.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir";
    src: url("/static/fonts/Avenir-Heavy.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Avenir";
    src: url("/static/fonts/Avenir-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Avenir";
    src: url("/static/fonts/Avenir-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Avenir";
    src: url("/static/fonts/Avenir-Book.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Avenir";
    src: url("/static/fonts/Avenir-Black.ttf") format("truetype");
    font-weight: 900;
}
