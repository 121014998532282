/* Font-Size Utility CSS */

.font-size-10-px {
    font-size: 10px !important;
}

.font-size-11-px {
    font-size: 11px !important;
}

.font-size-12-px {
    font-size: 12px !important;
}

.font-size-13-px {
    font-size: 13px !important;
}

.font-size-14-px {
    font-size: 14px !important;
}

.font-size-16-px {
    font-size: 16px !important;
}

.font-size-18-px {
    font-size: 18px !important;
}

.font-size-21-px {
    font-size: 21px !important;
}

.font-size-24-px {
    font-size: 24px !important;
}

.font-size-28-px {
    font-size: 28px !important;
}

.font-size-32-px {
    font-size: 32px !important;
}

.font-size-36-px {
    font-size: 36px !important;
}
