/* Icon Styles */
.datePickerIcon {
    width: 17px;
    height: 17px;
}

/* Triangle/Caret Styles */
.datePickerTriangle {
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #5a5a5a;
    clear: both;
}
