/* NumberInputInline styles */
.numberInputPreview {
    border-color: #dfdfdf;
}
.numberInputPreview.numberInputPreviewActive {
    z-index: 30;
    border-bottom-color: #fff;
}

.numberInputWrapper {
    min-width: 350px;
    max-width: 350px;
    border-color: #dfdfdf;
    transform: translateY(-1px);
    z-index: 20;
}
