/* * * * * */
/* DropdownButon styles */
.dropdownButton {
    outline: none;
    border: none;
    background: transparent;
    text-align: left;
    /* NOTE - the size variants here are hard-coded to exactly match the corresponding sizes for our standard Input components */
    /* The corresponding styles are defined in the `.form-input` CSS classes */
}

/* InputSize.sm */
.dropdownButtonSm {
    padding: 6px 10px;
}
.dropdownButtonSm p.label {
    font-size: 12px;
}
.dropdownButtonSm p.description {
    font-size: 10px;
    margin-top: 2px;
}

/* InputSize.md */
.dropdownButtonMd {
    padding: 8px 12px;
}
.dropdownButtonMd p.label {
    font-size: 14px;
}
.dropdownButtonMd p.description {
    font-size: 12px;
    margin-top: 3px;
}

/* InputSize.lg */
.dropdownButtonLg {
    padding: 13px 15px;
}
.dropdownButtonLg p.label {
    font-size: 16px;
}
.dropdownButtonLg p.description {
    font-size: 14px;
    margin-top: 5px;
}
