.triangle {
    content: "";
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 15px solid rgba(0, 0, 0, 0.1);
    clear: both;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}
.triangle:after {
    content: "";
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 14px solid #fff;
    clear: both;
    width: 0;
    position: absolute;
    height: 0;
    transform: translateX(-50%) translateY(-100%);
    top: -2.5px;
    left: 0;
}

.tooltipWrapper {
    position: relative;
}

.tooltipContainer {
    transition: opacity 0.2s ease-in;
    opacity: 0;
    z-index: -1;
    position: absolute;
    visibility: hidden;
}

.tooltipInnerContainer {
    padding: 15px;
}

.tooltipInnerWrapper {
    border-radius: 5px;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
}

.tooltipWrapper.top .tooltipContainer {
    top: -3px;
    transform: translate(-50%, -100%);
    left: 50%;
}

.tooltipWrapper.bottom .tooltipContainer {
    justify-content: flex-end;
    bottom: 2px;
    transform: translate(-50%, 100%);
    left: 50%;
}
.tooltipWrapper.bottom .triangle {
    transform: translateX(-50%) rotate(180deg);
    top: 0;
}

.tooltipWrapper.right .tooltipContainer {
    left: 100%;
    top: 50%;
    justify-content: center;
    transform: translateY(-50%);
    margin-left: 2px;
}
.tooltipWrapper.right .triangle {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    left: -5px;
}

.tooltipWrapper:hover .tooltipContainer,
.show .tooltipContainer {
    visibility: visible;
    opacity: 1;
    z-index: 101;
}
