.advancedFilterPillLongForm {
    height: fit-content;
    max-height: fit-content;
    min-height: 30px;
    line-height: 16px;
}
.advancedFilterPillLongForm:hover {
    text-decoration: none;
}

.advancedFilterPillsContainer {
    min-height: 40px;
}

/* AdvancedFiltersEditorOverlay styles */
.advancedFiltersEditorOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: #fff;
    padding: 0 100px;
    z-index: 300;
    margin: 0 0 0 90px;
    overflow: hidden;
}

.advancedFiltersEditorOverlayHeader {
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid #005bc0;
}

.advancedFiltersEditorOverlayScroll {
    overflow: scroll;
}

/* DateRangeSeparator styles */
.dateRangeSeparator {
    height: 1px;
    width: 10px;
}

/* ItemsPerPage styles */
.itemsPerPageLink {
    text-decoration: underline;
}

/* ActionDropdown Styles */

.actionDropdownWrapper {
    width: fit-content;
}

.dropdownListWrapperClassName {
    right: 0;
    border: 1px solid #e8e8e8 !important;
    border-radius: 4px 0 4px 4px !important;
    background: #fff;
    width: 175px;
}

.dropdownButtonOpenClassName {
    border-bottom: 1px solid transparent !important;
}

.dropdownLink {
    text-decoration: none !important;
}
.dropdownLink:hover {
    text-decoration: none !important;
    color: #005bc0;
}
