.activeWrapper {
    cursor: pointer;
    position: relative;
    z-index: 4;
    border-radius: 4px;
    padding-right: 15px;
    display: flex;
    align-items: center;
}

.activeWrapperOpen {
    border-radius: 4px 4px 0 0;
}

.activeWrapperOpenUp {
    border-radius: 0 0 4px 4px;
}

.dropdownWrapper {
    position: relative;
}

.dropdownList {
    overflow: auto;
    /* max height is set to 403px which consists of 10 elements that are usually 40px high and 3 borders
    (bottom border of the list, top and bottom borders of the active item) 1px each */
    max-height: 403px;
}

.dropdownGreyBorder {
    border: 1px solid #e8e8e8;
}

.dropdownListWrapper {
    border-top: none;
    overflow: hidden;
    border-radius: 0 0 4px 4px;
    transform: scaleY(0);
    position: absolute;
    min-width: 100%;
    transition: transform, opacity 0.2s ease-in;
    z-index: 13;
    transform-origin: top;
    opacity: 0;
}
.dropdownListWrapper.slideIn {
    transform: scaleY(1);
    opacity: 1;
}
.dropdownListWrapper.dropdownListWrapperUp {
    /* NOTE - we add the `bottom: 100%` rule so the absolutely positioned list is 100-percent-of-the-parent's height offset from the parent
    Essentially, this rule shifts the dropdownListWrapper by 1 unit of dropdown-button sizing.
    This approach is solid because it will work on dropdown menus with different heights */
    bottom: 100%;
    border-bottom: none;
    border-top: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
}

.searchBarWrapper {
    border-bottom: 1px solid #e8e8e8;
}
.searchBarWrapper > input {
    color: #5f6060;
}
.searchBarWrapper > input::placeholder {
    color: #bababb;
}

.searchBarInput {
    width: 100%;
    outline: none;
}
.searchBarInput::placeholder {
    font-size: 14px;
    font-family: "Open Sans";
}

.emptySearch {
    padding: 30px;
    text-align: center;
}

.emptySearchText {
    color: #bdbdbd;
}

.clearKeyword {
    text-decoration: underline;
    border: none;
    background: transparent;
}

.dropdownItem {
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
}
.dropdownItem:hover {
    background: #f9f9f9;
}
.dropdownItem:last-child {
    border-bottom: none;
}

.dropdownItemActive {
    background-color: #f9f9f9;
}
