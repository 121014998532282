/*
    Note: These files are declared globally and imported from _app.tsx
          in order to provide overrides for react-dates styling
*/

.dateInput {
    padding-right: 10px;
}

/* note: we have to give manually defined display values to the react component to override them */
.dateInput .DateRangePickerInput {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    display: flex;
}

.dateInput .DateInput {
    border: none;
    border-radius: 4px;
    width: 110px;
    background-color: red;
}

.dateInput .DateInput:last-child .DateInput_input {
    padding-left: 5px;
}

/* this override the date input with the same styles we have in the filter dropdown */
.dateInput .DateInput_input {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    color: #5f6060;
    font-size: 14px;
    font-family: "Open Sans";
    border: none;
    padding: 0 5px 0 30px;
}

.dateInput .DateInput_input:hover {
    cursor: pointer;
}

.dateInput .DateRangePicker_picker {
    z-index: 100;
}

/* this aligns the arrow in the center of the page */
.dateInput .DateRangePickerInput_arrow {
    display: flex;
    align-items: center;
}

/* this gives our custom colors to the date range selector */
.dateInput .DateInput_input__focused {
    border-color: #36cc87;
}

.dateInput .DateInput_fang {
    z-index: 101;
}

.dateInput .CalendarDay__selected,
.dateInput .CalendarDay__selected:active,
.dateInput .CalendarDay__selected:hover {
    background-color: #36cc87;
    border-color: #36cc87;
}

.dateInput .DateRangePickerInput_calendarIcon {
    position: absolute;
    z-index: 1;
    width: 16px;
    height: 16px;
    margin: 0;
    outline: none;
    padding: 0;
    left: 9px;
    top: 9px;
}

.dateInput .datePickerIcon {
    width: 100%;
    height: 100%;
}

.dateInput .datePickerIcon path {
    fill: #bababb;
}

.dateInput .dash {
    /* we use margin here so that we don't have to define a wrapper div (we don't want the padding to be part of the actual element) */
    width: 6px;
    height: 1px;
    background-color: #5a5a5a;
}

/* we have to absolutely position the carrets over the inputs manually */
.dateInput .dateWrapper {
    position: relative;
}

/* caret is the second range dropdown caret */
.dateInput .caret {
    position: absolute;
    right: 13px;
}

.dateInput .title {
    color: #bdbdbd;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    padding-right: 10px;
}
