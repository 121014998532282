/* Border Radius Classes */
.rounded-xs {
    border-radius: 3px !important; /* $xsBorderRadius */
}

.rounded-btn {
    border-radius: 4px !important; /* $buttonRadius */
}

.rounded-sm {
    border-radius: 5px !important; /* $smallBorderRadius */
}

.rounded {
    border-radius: 6px !important; /* $mediumBorderRadius */
}

.rounded-md {
    border-radius: 8px !important; /* $defaultBorderRadius */
}

.rounded-lg {
    border-radius: 10px !important; /* $largeBorderRadius */
}
