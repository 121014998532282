/* IMPORTANT - this is *not* a .module.css file because the .SingleDatePicker CSS names */
/* are globablly defined by the react-dates library. This file must be imported globally */
/* in both the Next.js app and in Storybook for the Datepicker override CSS to work correctly */

/* * * * */
/* Column Width styles */
.xsColumn {
    width: 100px;
    flex-grow: 100;
}

.smallColumn {
    width: 125px;
    flex-grow: 125;
}

.defaultColumn {
    width: 150px;
    flex-grow: 150;
}

.mediumColumn {
    width: 200px;
    flex-grow: 200;
}

.largeColumn {
    width: 250px;
    max-width: 250px;
    flex-grow: 250;
}

/* * * * */
.table {
    padding: 0;
    margin: 0;
    background: #fff;
}

.tableBordered {
    border: 1px solid #dfdfdf;
}

.tableHeaderCell {
    line-height: 35px;
    white-space: nowrap;
    padding: 0 0 0 10px;
}

.tableHeaderCellBordered {
    border-left: 1px solid #dfdfdf;
}
.tableHeaderCellBordered:first-child {
    border-left: none;
}

.tableHeaderLink {
    overflow: hidden;
    /* this is defined here and not using our utility classes so that it can be overridden in .tableHeaderLinkActive */
    color: #5a5a5a;
}
.tableHeaderLink:hover {
    text-decoration: none;
    color: #0e234e;
}

.tableHeaderLinkActive {
    /* this is defined in scss because we don't have a color class for $navyPrimary */
    color: #0e234e;
}

.tableHeaderLinkText {
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableRow {
    font-size: 16px;
    border-top: 1px solid #dfdfdf;
    background: #fff;
    /* we don't want to render the bottom border of cells in the last row */
}
.tableRow:last-of-type .expandableTableCellContent,
.tableRow:last-of-type .tableCell {
    border-bottom: none;
}

.tableCell {
    color: #5a5a5a;
    position: relative;
    border-left: 1px solid #dfdfdf;
    padding: 15px 10px;
}
.tableCell:first-child {
    border-left: none;
}
.tableCell:first-child .expandableTableCellContent {
    padding-left: 0;
    border-left: none;
}

/* TableRow.hover prop CSS */
.tableRowHover:hover {
    background: #f9f9f9 !important;
}
.tableRowHover:hover .tableCell {
    background: #f9f9f9;
}
.tableRowHover:hover .tableCell .expandableTableCellContent {
    background: #f9f9f9;
}
.tableRowHover:hover .stickyLeftCell {
    background: #f9f9f9;
}

/* * * * */
/* ExpandableTableCell Styles */
/* .expandableTableCell is applied to a table cell that should be expandable on hover. */
/* the padding and the borders are removed from it and are rendered in the nested */
/* div (the one with the `.expandableTableCellContent` class. it is so that we can render */
/* the expanded content outside the containing cell's borders while keeping the same height of the table row */
.expandableTableCell {
    padding: 0 !important;
    /* we need `!important` here to override the padding from `.tableCell` class */
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    /* expandableTableCellContent is the styling of the nested div inside the expandableTableCell. */
    /* without hover, its overflowing text is hidden and an ellipsis is shown instead. */
}
.expandableTableCell:hover {
    overflow: visible;
    z-index: 1;
    /* on hover, the following styles are added to the expandable cell's content */
}
.expandableTableCell:hover .expandableTableCellContent {
    overflow: visible;
    width: auto;
    border-right: 1px solid #dfdfdf;
    flex-grow: 1;
    background: #fff;
    /* we set the background here because otherwise the underlying cells would be visible on hover */
    /* NOTE - this is applied by default, but it's been broken out into a separate CSS class because we don't _always_ want a background color change on hover */
    /* Expand ExpandableTableCell to the left, instead of right */
}
.expandableTableCell:hover
    .expandableTableCellContent.expandableTableCellHoverBackground {
    background: #fafafa;
}
.expandableTableCell:hover
    .expandableTableCellContent.expandableTableCellExpandLeft {
    position: absolute;
    right: 0;
    border-left: 1px solid #dfdfdf;
    border-right: none;
}
.expandableTableCell .expandableTableCellContent {
    /* this fixes double right border on hover when the cell doesn't have overflowing content */
    margin-right: -1px;
    width: 100%;
    height: 100%;
}

/* expandableCellContentWrapper is required to make text-overflow:ellipsis work for expandable cell content. it doesn't work when applied to .expandableTableCellContent because that has display:flex which is used to center the content vertically */
.expandableCellContentWrapper {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* * * * */
/* VerticalExpandableCell Styles */
/* .verticalExpandableCell is applied to a table cell that should be vertically expandable on hover */
.verticalExpandableCell {
    position: relative;
    white-space: nowrap;
    /* verticalExpandableCellContent is the styling of the nested div inside the verticalExpandableCell. */
    /* without hover, it is rendered as a single line ending with ellipsis if there's too much content */
}
.verticalExpandableCell:hover .verticalExpandableCellContent {
    /* we set the background here because otherwise the underlying cells would be visible on hover */
    background: #fff;
    border: 1px solid #dfdfdf;
    position: absolute;
    z-index: 1;
    top: 0;
    white-space: normal;
    /* this negative margin prevents double border on hover */
    margin: -1px;
    /* 2 * $cellBorderWidth compensates the negative margin */
    width: calc(100% + 2px);
    /* Ensures word break will take effect on any character, not just whitespace */
    word-break: break-all;
    min-height: calc(100% + 2px);
    display: flex;
    align-items: center;
    /* this class is applied when we want a background color change on hover */
}
.verticalExpandableCell:hover
    .verticalExpandableCellContent.verticalExpandableTableCellHoverBackground {
    background: #fafafa;
}
.verticalExpandableCell:first-child:hover .verticalExpandableCellContent {
    /* the first cell in a row shouldn't have the left border on hover */
    border-left: none;
    /* no need to prevent the double border on the left */
    margin-left: 0;
    /* thus, we only need to compensate the right negative margin so just $cellBorderWidth here */
    width: calc(100% + 1px);
}
.verticalExpandableCell .verticalExpandableCellContent {
    padding: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* * * * */
.lastCell {
    border-top: 1px solid #dfdfdf;
}

.tableRowLink {
    width: 100%;
}
.tableRowLink:hover,
.tableRowActive .tableRowLink {
    text-decoration: none;
    background-color: #fafafa;
}

.columnSwitcherTitle {
    padding: 10px;
}

.columnSwitcherItem {
    width: 100%;
    color: #5a5a5a;
    padding: 5px 0;
}
.columnSwitcherItem:hover {
    color: #5a5a5a;
    text-decoration: none;
}

.checkMark {
    /* width and height were taken directly from invision */
    width: 8px;
    height: 10px;
}
.checkMark path {
    fill: #5a5a5a;
}

/* * * * */
/* Row Sizes */
.smallRow {
    height: 42px;
}

.defaultRow {
    height: 50px;
}

.largeRow {
    height: 70px;
}

.autoRow {
    height: auto;
}

/* * * * */
/* TableRowExpandable Styles */
/* Cell that contains the toggle button - must render */
/* consistently for rows without where the toggle button is hidden */
.toggleCell {
    min-width: 30px !important;
    max-width: 30px !important;
    width: 30px !important;
}
/* Applied when the .toggleCell needs stickyLeft styles */
.toggleCell.toggleCellStickyLeft {
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 11;
}

/* Modifies the first table cell in the table row to remove the left-border */
.tableRowExpandable .tableCell:nth-child(2) {
    border-left: none;
}

/* Wrapper for the expandable content */
.expandableContentWrapper {
    background: #f5f6f9;
    box-shadow: inset 0px 10px 13px -13px rgba(155, 155, 155, 0.65),
        inset 0px -10px 13px -13px rgba(155, 155, 155, 0.65);
}

/* * * * */
/* StickyLeft Styles */
/* StickyLeft styles for TableCell */
.stickyLeftCell {
    /* Base Styles */
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 10;
    border-left: none;
    border-right: 1px solid #dfdfdf;
    box-shadow: 4px 0 3px 0px rgba(0, 0, 0, 0.05);
    /* Targets the next sibling to the .stickyLeftCell element */
    /* This is done so the box-shadow above doesn't */
    /* create visual noise with the left-border on the adjacent cell */
    /* Updates the stickyLeftCell left property to */
    /* include enough clearance for the .toggleCell */
}
.stickyLeftCell + .tableCell {
    border-left: none;
}
.stickyLeftCell.expandableRowTogglerCell {
    left: 30px !important;
}

/* StickyLeft styles for TableHeaderCell */
.stickyLeftHeaderCell {
    /* Base Styles */
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 10;
    border-left: none;
    border-right: 1px solid #dfdfdf;
    box-shadow: 4px 0 3px 0px rgba(0, 0, 0, 0.05);
    /* Targets the next sibling to the .stickyLeftCell element */
    /* This is done so the box-shadow above doesn't */
    /* create visual noise with the left-border on the adjacent cell */
    /* Defines styles to include when the header cell is stickyLeft */
    /* and includes toggle button for expandable rows */
}
.stickyLeftHeaderCell + .tableHeaderCell {
    border-left: none;
}
.stickyLeftHeaderCell.stickyLeftExpandableHeaderCell {
    /* Defines padding left as sum of the */
    /* $rowPadding (default padding and the toggleCell width */
    padding-left: 40px !important;
    /* Redefines column width CSS classes to include */
    /* the additional width from the .toggleCell element */
}
.stickyLeftHeaderCell.stickyLeftExpandableHeaderCell.xsColumn {
    width: 130px;
    flex-grow: 130px;
}
.stickyLeftHeaderCell.stickyLeftExpandableHeaderCell.smallColumn {
    width: 155px;
    flex-grow: 155px;
}
.stickyLeftHeaderCell.stickyLeftExpandableHeaderCell.defaultColumn {
    width: 180px;
    flex-grow: 180px;
}
.stickyLeftHeaderCell.stickyLeftExpandableHeaderCell.mediumColumn {
    width: 230px;
    flex-grow: 230px;
}
.stickyLeftHeaderCell.stickyLeftExpandableHeaderCell.largeColumn {
    width: 280px;
    max-width: 280px;
    flex-grow: 280px;
}

/* * * * */
/* ScrollableTable Styles */
/* Both the TableHeader and TableBody are independently scrollable in the */
/* x direction - we use some JS in the `ScrollableTableBody` component to sync the scroll state between the two elements */
.scrollableTable {
    /* NOTE - we're going to circle back to this and figure out a better solution for communicating that */
    /* there are additional columns that aren't visible in the table. */
    /* box-shadow: inset -4px 0 3px 0px rgba(0, 0, 0, 0.05; */
    box-shadow: none;
}

.scrollableTableBody {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll; /* Enables the TableBody's content to scroll horizontally */
    overflow-y: hidden; /* Ensures that the TableBody wrapper element doesn't scroll vertically (note that nothing is cut off */
    overscroll-behavior-x: contain;
    /* Ensures that scroll events don't propagate, preventing unintentional page navigation in MacOS */
    /* Removes border from the first ROW because the scrollableTable's drop-shadow */
    /* Ensures last table cell in each row has a right-border */
    /* Ensures the above styles are _NOT_ applied inside .expandableContentWrapper */
}
.scrollableTableBody .tableRow:first-child {
    border-top: none !important;
}
.scrollableTableBody .tableCell:last-of-type {
    border-right: 1px solid #dfdfdf !important;
}
.scrollableTableBody .expandableContentWrapper .tableCell:first-child,
.scrollableTableBody .expandableContentWrapper .tableCell:nth-child(2) {
    border-left: 1px solid #dfdfdf !important;
}

/* The TableHeader scrolls horizontally, is sticky with the top of the page when scrolling vertically. */
.scrollableHeader {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll; /* Enables the TableHeader's content to scroll horizontally */
    overflow-y: hidden; /* Prevents the header contents from scrolling vertically */
    overscroll-behavior-x: contain; /* Ensures that scroll events don't propagate, preventing unintentional page navigation in MacOS */
    padding: 0;
    position: sticky; /* Sticks to the top of the page when scrolling vertically */
    top: 0;
    background: #fff;
    z-index: 12;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #dfdfdf;
    /* Firefox - hides scrollbar */
    scrollbar-width: none;
}
.scrollableHeader::-webkit-scrollbar {
    /* Chrome + Safari - hides scrollbar */
    display: none;
}
