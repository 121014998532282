/* IMPORTANT - this is *not* a .module.css file because the .SingleDatePicker CSS names */
/* are globablly defined by the react-dates library. This file must be imported globally */
/* in both the Next.js app and in Storybook for the Datepicker override CSS to work correctly */

/* NOTE: we override the .DateInput_fang style here to handle a */
/* UI bug when the `openDirection` prop is set to `up`. When `openDirection` is set to `down`, the component */
/* automatically sets the `bottom` CSS rule inline, thus overriding the rule below. That inline override */
/* does not occur when the `openDirection` prop is set to `up`. */
.datePickerOverride .SingleDatePicker {
    width: 100%;
}
.datePickerOverride .SingleDatePicker_picker {
    z-index: 15;
}
.datePickerOverride .SingleDatePickerInput {
    border-radius: 4px;
    border: 1px solid #dfdfdf; /* $lightBorder */
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.datePickerOverride .SingleDatePickerInput_calendarIcon {
    outline: none;
    margin: unset;
    padding: 10px 15px 10px 20px;
}
.datePickerOverride .DateInput {
    border-radius: 4px;
    display: flex;
    flex-grow: 1;
}
.datePickerOverride .DateInput_fang {
    bottom: 58px;
}
.datePickerOverride .DateInput_input {
    border-radius: 4px;
    color: #5a5a5a; /* $black; */
    font-weight: 400;
    font-size: 16px;
    border: none;
    padding: 9px 12px;
}
.datePickerOverride .DateInput_input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bababb; /* $greyDim */
    font-weight: 18px;
    font-size: 16px;
}
.datePickerOverride .DateInput_input::-moz-placeholder {
    /* Firefox 19+ */
    color: #bababb;
    font-weight: 18px;
    font-size: 16px;
}
.datePickerOverride .DateInput_input:-ms-input-placeholder {
    /* IE 10+ */
    color: #bababb;
    font-weight: 18px;
    font-size: 16px;
}
.datePickerOverride .DateInput_input__focused {
    border-color: #36cc87; /* $coreGreen */
}
.datePickerOverride .CalendarDay__selected,
.datePickerOverride .CalendarDay__selected:active,
.datePickerOverride .CalendarDay__selected:hover {
    background-color: #36cc87; /* $coreGreen */
    border-color: #36cc87; /* $coreGreen */
}

/* * * * */
/* Small Variant styles */
.datePickerOverrideSm .SingleDatePickerInput {
    display: flex; /* Ensures that input and calendarIcon stack horizontally at small scale */
    flex-direction: row; /* Ensures that input and calendarIcon stack horizontally at small scale */
}

.datePickerOverrideSm .DateInput_input {
    font-size: 14px !important;
    padding: 2px 5px !important;
}

.datePickerOverrideSm .SingleDatePickerInput_calendarIcon {
    margin-right: 5px !important;
    padding: 5px !important;
}

/* * * * */
/* Medium Variant styles */
.datePickerOverrideMd .SingleDatePickerInput {
    display: flex; /* Ensures that input and calendarIcon stack horizontally at small scale */
    flex-direction: row; /* Ensures that input and calendarIcon stack horizontally at small scale */
}

.datePickerOverrideMd .DateInput_input {
    padding: 4px 5px !important;
}

.datePickerOverrideMd .SingleDatePickerInput_calendarIcon {
    margin-right: 5px !important;
    padding: 5px !important;
}

.datePickerOverrideMd .DateInput_input::placeholder {
    font-size: 14px;
}
