/* * * * * */
/* SuggestedFilterDropdown styles */
.dropdownListWrapperClassName {
    right: 0;
    border: 1px solid #e8e8e8; /* $greyBorder */
    background: #fff;
}

.dropdownButton:hover {
    background: #f5f6f9; /* $backgroundMain */
    color: #005bc0; /* $primary */
}

.dropdownLink {
    text-decoration: none !important;
}

/* * * * * */
/* SuggestedFilterCard styles */
.tags {
    text-transform: capitalize;
}

.footer {
    min-height: 30px;
}
