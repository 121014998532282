/* Border Colors */
.border-black {
    border: 1px solid #5a5a5a !important; /* $black */
}

.border-grey {
    border: 1px solid #e8e8e8 !important; /* $greyBorder */
}

.border-bottom-grey {
    border-bottom: 1px solid #e8e8e8 !important; /* $greyBorder */
}

/* Border + Outline Utils */
.outline-none {
    outline: none !important;
}

.border-none {
    border: none !important;
}

.border-l-none {
    border-left: none !important;
}

.border-r-none {
    border-right: none !important;
}

.border-t-none {
    border-top: none !important;
}

.border-b-none {
    border-bottom: none !important;
}
