/* * * * */
/* Text Alignment Classes */
.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

/* * * * */
/* Text Whitespace modifiers */
.whitespace-nowrap {
    white-space: nowrap !important;
}

/* * * * */
/* Text Casing Modifiers */
.text-uppercase {
    text-transform: uppercase;
}
