/* * * * */
/* Font Weight Classes */
.font-weight-normal {
    font-weight: 400 !important;
}

/* * * * */
/* Updated Font Weight Classes */
.font-primary {
    font-family: "brandon-grotesque" !important;
    font-weight: 400 !important;
}

.font-primary-bold {
    font-family: "brandon-grotesque" !important;
    /* we don't have a font for Brandon Grotesque 600 so we use 500 here */
    font-weight: 500 !important;
}

.font-primary-heavy {
    font-family: "brandon-grotesque" !important;
    font-weight: 700 !important;
}

.font-secondary {
    font-family: "Open Sans" !important;
    font-weight: 400 !important;
}

.font-secondary-bold {
    font-family: "Open Sans" !important;
    font-weight: 600 !important;
}

.font-secondary-heavy {
    font-family: "Open Sans" !important;
    font-weight: 700 !important;
}
