.checkBox {
    /* width and height were taken directly from invision */
    width: 20px;
    height: 20px;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
}

.checkMark {
    /* width and height were taken directly from invision */
    width: 15px;
    height: 12px;
}

.checkMark path {
    fill: #0e234e;
}
