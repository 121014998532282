/* Button Utility CSS */
/* TODO - phase these out one-by-one */
.green-round-button {
    background-color: #2acd85;
    border: 1px solid #2acd85;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.green-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #26b877;
    border: 1px solid #26b877;
}
.green-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.green-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-green-round-button {
    background-color: #36cc87;
    border: 1px solid #36cc87;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.core-green-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #2fb97a;
    border: 1px solid #2fb97a;
}
.core-green-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.core-green-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.btn-green-round-button {
    background-color: #57d693;
    border: 1px solid #57d693;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.btn-green-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #43d186;
    border: 1px solid #43d186;
}
.btn-green-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.btn-green-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.blue-round-button {
    background-color: #4f9cff;
    border: 1px solid #4f9cff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.blue-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #368eff;
    border: 1px solid #368eff;
}
.blue-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.blue-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-blue-round-button {
    background-color: #539eff;
    border: 1px solid #539eff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.core-blue-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #3a90ff;
    border: 1px solid #3a90ff;
}
.core-blue-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.core-blue-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.red-round-button {
    background-color: #ff5050;
    border: 1px solid #ff5050;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.red-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #ff3737;
    border: 1px solid #ff3737;
}
.red-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.red-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.orange-round-button {
    background-color: #fd963b;
    border: 1px solid #fd963b;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.orange-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #fd8822;
    border: 1px solid #fd8822;
}
.orange-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.orange-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.dark-blue-round-button {
    background-color: #082f57;
    border: 1px solid #082f57;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.dark-blue-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #062240;
    border: 1px solid #062240;
}
.dark-blue-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.dark-blue-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.disabled-round-button {
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.disabled-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #b0b0b0;
    border: 1px solid #b0b0b0;
}
.disabled-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.disabled-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.light-grey-round-button {
    background-color: #dfdfdf;
    border: 1px solid #dfdfdf;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.light-grey-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.light-grey-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.light-grey-round-button:disabled {
    cursor: default;
}

.black-round-button {
    background-color: #5a5a5a;
    border: 1px solid #5a5a5a;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.black-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #4d4d4d;
    border: 1px solid #4d4d4d;
}
.black-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.black-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-white-round-button {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-white-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
}
.transparent-white-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.transparent-white-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-blue-black-round-button {
    background-color: transparent;
    border: 1px solid #4f9cff;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-blue-black-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-blue-black-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-blue-black-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-blue-round-button {
    background-color: transparent;
    border: 1px solid #4f9cff;
    color: #4f9cff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-blue-round-button:hover {
    text-decoration: none;
    color: #4f9cff;
}
.transparent-blue-round-button:focus {
    text-decoration: none;
    color: #4f9cff;
    outline: none;
}
.transparent-blue-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-core-blue-round-button {
    background-color: transparent;
    border: 1px solid #539eff;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-core-blue-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.transparent-core-blue-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.transparent-core-blue-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-dark-blue-round-button {
    background-color: transparent;
    border: 1px solid #082f57;
    color: #082f57;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-dark-blue-round-button:hover {
    text-decoration: none;
    color: #082f57;
}
.transparent-dark-blue-round-button:focus {
    text-decoration: none;
    color: #082f57;
    outline: none;
}
.transparent-dark-blue-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-green-round-button {
    background-color: transparent;
    border: 1px solid #2acd85;
    color: #2acd85;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-green-round-button:hover {
    text-decoration: none;
    color: #2acd85;
}
.transparent-green-round-button:focus {
    text-decoration: none;
    color: #2acd85;
    outline: none;
}
.transparent-green-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-grey-round-button {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    color: #bdbdbd;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-grey-round-button:hover {
    text-decoration: none;
    color: #bdbdbd;
}
.transparent-grey-round-button:focus {
    text-decoration: none;
    color: #bdbdbd;
    outline: none;
}
.transparent-grey-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-grey-black-round-button {
    background-color: transparent;
    border: 1px solid #d6d6d5;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-grey-black-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-grey-black-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-grey-black-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-light-grey-round-button {
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: #dfdfdf;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-light-grey-round-button:hover {
    text-decoration: none;
    color: #dfdfdf;
}
.transparent-light-grey-round-button:focus {
    text-decoration: none;
    color: #dfdfdf;
    outline: none;
}
.transparent-light-grey-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-light-grey-black-round-button {
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-light-grey-black-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.transparent-light-grey-black-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.transparent-light-grey-black-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-red-round-button {
    background-color: transparent;
    border: 1px solid #ff5050;
    color: #ff5050;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-red-round-button:hover {
    text-decoration: none;
    color: #ff5050;
}
.transparent-red-round-button:focus {
    text-decoration: none;
    color: #ff5050;
    outline: none;
}
.transparent-red-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-orange-round-button {
    background-color: transparent;
    border: 1px solid #fd963b;
    color: #fd963b;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-orange-round-button:hover {
    text-decoration: none;
    color: #fd963b;
}
.transparent-orange-round-button:focus {
    text-decoration: none;
    color: #fd963b;
    outline: none;
}
.transparent-orange-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-black-round-button {
    background-color: transparent;
    border: 1px solid #5a5a5a;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-black-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-black-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-black-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-yellow-round-button {
    background-color: transparent;
    border: 1px solid #fdb249;
    color: #fdb249;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-yellow-round-button:hover {
    text-decoration: none;
    color: #fdb249;
}
.transparent-yellow-round-button:focus {
    text-decoration: none;
    color: #fdb249;
    outline: none;
}
.transparent-yellow-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.white-light-grey-black-round-button {
    background-color: #fff;
    border: 1px solid #dfdfdf;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.white-light-grey-black-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.white-light-grey-black-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.white-light-grey-black-round-button:disabled {
    cursor: default;
}

.transparent-black-red-round-button {
    background-color: transparent;
    border: 1px solid #ff5050;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-black-red-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.transparent-black-red-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.transparent-black-red-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-core-blue-grey-round-button {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.transparent-core-blue-grey-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.transparent-core-blue-grey-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.transparent-core-blue-grey-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-blue-white-round-button {
    background-color: #fff;
    border: 1px solid #fff;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
.core-blue-white-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.core-blue-white-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.core-blue-white-round-button:disabled {
    cursor: default;
}

.green-lg-round-button {
    background-color: #2acd85;
    border: 1px solid #2acd85;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.green-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #26b877;
    border: 1px solid #26b877;
}
.green-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.green-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-green-lg-round-button {
    background-color: #36cc87;
    border: 1px solid #36cc87;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.core-green-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #2fb97a;
    border: 1px solid #2fb97a;
}
.core-green-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.core-green-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.btn-green-lg-round-button {
    background-color: #57d693;
    border: 1px solid #57d693;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.btn-green-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #43d186;
    border: 1px solid #43d186;
}
.btn-green-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.btn-green-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.blue-lg-round-button {
    background-color: #4f9cff;
    border: 1px solid #4f9cff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.blue-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #368eff;
    border: 1px solid #368eff;
}
.blue-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.blue-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-blue-lg-round-button {
    background-color: #539eff;
    border: 1px solid #539eff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.core-blue-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #3a90ff;
    border: 1px solid #3a90ff;
}
.core-blue-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.core-blue-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.red-lg-round-button {
    background-color: #ff5050;
    border: 1px solid #ff5050;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.red-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #ff3737;
    border: 1px solid #ff3737;
}
.red-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.red-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.orange-lg-round-button {
    background-color: #fd963b;
    border: 1px solid #fd963b;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.orange-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #fd8822;
    border: 1px solid #fd8822;
}
.orange-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.orange-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.dark-blue-lg-round-button {
    background-color: #082f57;
    border: 1px solid #082f57;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.dark-blue-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #062240;
    border: 1px solid #062240;
}
.dark-blue-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.dark-blue-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.disabled-lg-round-button {
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.disabled-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #b0b0b0;
    border: 1px solid #b0b0b0;
}
.disabled-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.disabled-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.light-grey-lg-round-button {
    background-color: #dfdfdf;
    border: 1px solid #dfdfdf;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.light-grey-lg-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.light-grey-lg-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.light-grey-lg-round-button:disabled {
    cursor: default;
}

.black-lg-round-button {
    background-color: #5a5a5a;
    border: 1px solid #5a5a5a;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.black-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #4d4d4d;
    border: 1px solid #4d4d4d;
}
.black-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.black-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-white-lg-round-button {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-white-lg-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
}
.transparent-white-lg-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.transparent-white-lg-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-blue-black-lg-round-button {
    background-color: transparent;
    border: 1px solid #4f9cff;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-blue-black-lg-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-blue-black-lg-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-blue-black-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-blue-lg-round-button {
    background-color: transparent;
    border: 1px solid #4f9cff;
    color: #4f9cff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-blue-lg-round-button:hover {
    text-decoration: none;
    color: #4f9cff;
}
.transparent-blue-lg-round-button:focus {
    text-decoration: none;
    color: #4f9cff;
    outline: none;
}
.transparent-blue-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-core-blue-lg-round-button {
    background-color: transparent;
    border: 1px solid #539eff;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-core-blue-lg-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.transparent-core-blue-lg-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.transparent-core-blue-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-dark-blue-lg-round-button {
    background-color: transparent;
    border: 1px solid #082f57;
    color: #082f57;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-dark-blue-lg-round-button:hover {
    text-decoration: none;
    color: #082f57;
}
.transparent-dark-blue-lg-round-button:focus {
    text-decoration: none;
    color: #082f57;
    outline: none;
}
.transparent-dark-blue-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-green-lg-round-button {
    background-color: transparent;
    border: 1px solid #2acd85;
    color: #2acd85;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-green-lg-round-button:hover {
    text-decoration: none;
    color: #2acd85;
}
.transparent-green-lg-round-button:focus {
    text-decoration: none;
    color: #2acd85;
    outline: none;
}
.transparent-green-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-grey-lg-round-button {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    color: #bdbdbd;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-grey-lg-round-button:hover {
    text-decoration: none;
    color: #bdbdbd;
}
.transparent-grey-lg-round-button:focus {
    text-decoration: none;
    color: #bdbdbd;
    outline: none;
}
.transparent-grey-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-grey-black-lg-round-button {
    background-color: transparent;
    border: 1px solid #d6d6d5;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-grey-black-lg-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-grey-black-lg-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-grey-black-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-light-grey-lg-round-button {
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: #dfdfdf;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-light-grey-lg-round-button:hover {
    text-decoration: none;
    color: #dfdfdf;
}
.transparent-light-grey-lg-round-button:focus {
    text-decoration: none;
    color: #dfdfdf;
    outline: none;
}
.transparent-light-grey-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-light-grey-black-lg-round-button {
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-light-grey-black-lg-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.transparent-light-grey-black-lg-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.transparent-light-grey-black-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-red-lg-round-button {
    background-color: transparent;
    border: 1px solid #ff5050;
    color: #ff5050;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-red-lg-round-button:hover {
    text-decoration: none;
    color: #ff5050;
}
.transparent-red-lg-round-button:focus {
    text-decoration: none;
    color: #ff5050;
    outline: none;
}
.transparent-red-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-orange-lg-round-button {
    background-color: transparent;
    border: 1px solid #fd963b;
    color: #fd963b;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-orange-lg-round-button:hover {
    text-decoration: none;
    color: #fd963b;
}
.transparent-orange-lg-round-button:focus {
    text-decoration: none;
    color: #fd963b;
    outline: none;
}
.transparent-orange-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-black-lg-round-button {
    background-color: transparent;
    border: 1px solid #5a5a5a;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-black-lg-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-black-lg-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-black-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-yellow-lg-round-button {
    background-color: transparent;
    border: 1px solid #fdb249;
    color: #fdb249;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-yellow-lg-round-button:hover {
    text-decoration: none;
    color: #fdb249;
}
.transparent-yellow-lg-round-button:focus {
    text-decoration: none;
    color: #fdb249;
    outline: none;
}
.transparent-yellow-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.white-light-grey-black-lg-round-button {
    background-color: #fff;
    border: 1px solid #dfdfdf;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.white-light-grey-black-lg-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.white-light-grey-black-lg-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.white-light-grey-black-lg-round-button:disabled {
    cursor: default;
}

.transparent-black-red-lg-round-button {
    background-color: transparent;
    border: 1px solid #ff5050;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-black-red-lg-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.transparent-black-red-lg-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.transparent-black-red-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-core-blue-grey-lg-round-button {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.transparent-core-blue-grey-lg-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.transparent-core-blue-grey-lg-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.transparent-core-blue-grey-lg-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-blue-white-lg-round-button {
    background-color: #fff;
    border: 1px solid #fff;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.core-blue-white-lg-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.core-blue-white-lg-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.core-blue-white-lg-round-button:disabled {
    cursor: default;
}

.green-sml-round-button {
    background-color: #2acd85;
    border: 1px solid #2acd85;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.green-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #26b877;
    border: 1px solid #26b877;
}
.green-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.green-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-green-sml-round-button {
    background-color: #36cc87;
    border: 1px solid #36cc87;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.core-green-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #2fb97a;
    border: 1px solid #2fb97a;
}
.core-green-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.core-green-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.btn-green-sml-round-button {
    background-color: #57d693;
    border: 1px solid #57d693;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.btn-green-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #43d186;
    border: 1px solid #43d186;
}
.btn-green-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.btn-green-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.blue-sml-round-button {
    background-color: #4f9cff;
    border: 1px solid #4f9cff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.blue-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #368eff;
    border: 1px solid #368eff;
}
.blue-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.blue-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-blue-sml-round-button {
    background-color: #539eff;
    border: 1px solid #539eff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.core-blue-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #3a90ff;
    border: 1px solid #3a90ff;
}
.core-blue-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.core-blue-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.red-sml-round-button {
    background-color: #ff5050;
    border: 1px solid #ff5050;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.red-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #ff3737;
    border: 1px solid #ff3737;
}
.red-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.red-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.orange-sml-round-button {
    background-color: #fd963b;
    border: 1px solid #fd963b;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.orange-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #fd8822;
    border: 1px solid #fd8822;
}
.orange-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.orange-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.dark-blue-sml-round-button {
    background-color: #082f57;
    border: 1px solid #082f57;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.dark-blue-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #062240;
    border: 1px solid #062240;
}
.dark-blue-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.dark-blue-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.disabled-sml-round-button {
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.disabled-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #b0b0b0;
    border: 1px solid #b0b0b0;
}
.disabled-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.disabled-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.light-grey-sml-round-button {
    background-color: #dfdfdf;
    border: 1px solid #dfdfdf;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.light-grey-sml-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.light-grey-sml-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.light-grey-sml-round-button:disabled {
    cursor: default;
}

.black-sml-round-button {
    background-color: #5a5a5a;
    border: 1px solid #5a5a5a;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.black-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #4d4d4d;
    border: 1px solid #4d4d4d;
}
.black-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.black-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-white-sml-round-button {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-white-sml-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
}
.transparent-white-sml-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.transparent-white-sml-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-blue-black-sml-round-button {
    background-color: transparent;
    border: 1px solid #4f9cff;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-blue-black-sml-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-blue-black-sml-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-blue-black-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-blue-sml-round-button {
    background-color: transparent;
    border: 1px solid #4f9cff;
    color: #4f9cff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-blue-sml-round-button:hover {
    text-decoration: none;
    color: #4f9cff;
}
.transparent-blue-sml-round-button:focus {
    text-decoration: none;
    color: #4f9cff;
    outline: none;
}
.transparent-blue-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-core-blue-sml-round-button {
    background-color: transparent;
    border: 1px solid #539eff;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-core-blue-sml-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.transparent-core-blue-sml-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.transparent-core-blue-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-dark-blue-sml-round-button {
    background-color: transparent;
    border: 1px solid #082f57;
    color: #082f57;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-dark-blue-sml-round-button:hover {
    text-decoration: none;
    color: #082f57;
}
.transparent-dark-blue-sml-round-button:focus {
    text-decoration: none;
    color: #082f57;
    outline: none;
}
.transparent-dark-blue-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-green-sml-round-button {
    background-color: transparent;
    border: 1px solid #2acd85;
    color: #2acd85;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-green-sml-round-button:hover {
    text-decoration: none;
    color: #2acd85;
}
.transparent-green-sml-round-button:focus {
    text-decoration: none;
    color: #2acd85;
    outline: none;
}
.transparent-green-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-grey-sml-round-button {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    color: #bdbdbd;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-grey-sml-round-button:hover {
    text-decoration: none;
    color: #bdbdbd;
}
.transparent-grey-sml-round-button:focus {
    text-decoration: none;
    color: #bdbdbd;
    outline: none;
}
.transparent-grey-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-grey-black-sml-round-button {
    background-color: transparent;
    border: 1px solid #d6d6d5;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-grey-black-sml-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-grey-black-sml-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-grey-black-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-light-grey-sml-round-button {
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: #dfdfdf;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-light-grey-sml-round-button:hover {
    text-decoration: none;
    color: #dfdfdf;
}
.transparent-light-grey-sml-round-button:focus {
    text-decoration: none;
    color: #dfdfdf;
    outline: none;
}
.transparent-light-grey-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-light-grey-black-sml-round-button {
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-light-grey-black-sml-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.transparent-light-grey-black-sml-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.transparent-light-grey-black-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-red-sml-round-button {
    background-color: transparent;
    border: 1px solid #ff5050;
    color: #ff5050;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-red-sml-round-button:hover {
    text-decoration: none;
    color: #ff5050;
}
.transparent-red-sml-round-button:focus {
    text-decoration: none;
    color: #ff5050;
    outline: none;
}
.transparent-red-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-orange-sml-round-button {
    background-color: transparent;
    border: 1px solid #fd963b;
    color: #fd963b;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-orange-sml-round-button:hover {
    text-decoration: none;
    color: #fd963b;
}
.transparent-orange-sml-round-button:focus {
    text-decoration: none;
    color: #fd963b;
    outline: none;
}
.transparent-orange-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-black-sml-round-button {
    background-color: transparent;
    border: 1px solid #5a5a5a;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-black-sml-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-black-sml-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-black-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-yellow-sml-round-button {
    background-color: transparent;
    border: 1px solid #fdb249;
    color: #fdb249;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-yellow-sml-round-button:hover {
    text-decoration: none;
    color: #fdb249;
}
.transparent-yellow-sml-round-button:focus {
    text-decoration: none;
    color: #fdb249;
    outline: none;
}
.transparent-yellow-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.white-light-grey-black-sml-round-button {
    background-color: #fff;
    border: 1px solid #dfdfdf;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.white-light-grey-black-sml-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.white-light-grey-black-sml-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.white-light-grey-black-sml-round-button:disabled {
    cursor: default;
}

.transparent-black-red-sml-round-button {
    background-color: transparent;
    border: 1px solid #ff5050;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-black-red-sml-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.transparent-black-red-sml-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.transparent-black-red-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-core-blue-grey-sml-round-button {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.transparent-core-blue-grey-sml-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.transparent-core-blue-grey-sml-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.transparent-core-blue-grey-sml-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-blue-white-sml-round-button {
    background-color: #fff;
    border: 1px solid #fff;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    padding: 8px 12px;
}
.core-blue-white-sml-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.core-blue-white-sml-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.core-blue-white-sml-round-button:disabled {
    cursor: default;
}

.green-cta-round-button {
    background-color: #2acd85;
    border: 1px solid #2acd85;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.green-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #26b877;
    border: 1px solid #26b877;
}
.green-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.green-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-green-cta-round-button {
    background-color: #36cc87;
    border: 1px solid #36cc87;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.core-green-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #2fb97a;
    border: 1px solid #2fb97a;
}
.core-green-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.core-green-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.btn-green-cta-round-button {
    background-color: #57d693;
    border: 1px solid #57d693;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.btn-green-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #43d186;
    border: 1px solid #43d186;
}
.btn-green-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.btn-green-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.blue-cta-round-button {
    background-color: #4f9cff;
    border: 1px solid #4f9cff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.blue-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #368eff;
    border: 1px solid #368eff;
}
.blue-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.blue-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-blue-cta-round-button {
    background-color: #539eff;
    border: 1px solid #539eff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.core-blue-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #3a90ff;
    border: 1px solid #3a90ff;
}
.core-blue-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.core-blue-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.red-cta-round-button {
    background-color: #ff5050;
    border: 1px solid #ff5050;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.red-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #ff3737;
    border: 1px solid #ff3737;
}
.red-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.red-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.orange-cta-round-button {
    background-color: #fd963b;
    border: 1px solid #fd963b;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.orange-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #fd8822;
    border: 1px solid #fd8822;
}
.orange-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.orange-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.dark-blue-cta-round-button {
    background-color: #082f57;
    border: 1px solid #082f57;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.dark-blue-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #062240;
    border: 1px solid #062240;
}
.dark-blue-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.dark-blue-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.disabled-cta-round-button {
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.disabled-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #b0b0b0;
    border: 1px solid #b0b0b0;
}
.disabled-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.disabled-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.light-grey-cta-round-button {
    background-color: #dfdfdf;
    border: 1px solid #dfdfdf;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.light-grey-cta-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.light-grey-cta-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.light-grey-cta-round-button:disabled {
    cursor: default;
}

.black-cta-round-button {
    background-color: #5a5a5a;
    border: 1px solid #5a5a5a;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.black-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #4d4d4d;
    border: 1px solid #4d4d4d;
}
.black-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.black-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-white-cta-round-button {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-white-cta-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
}
.transparent-white-cta-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.transparent-white-cta-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-blue-black-cta-round-button {
    background-color: transparent;
    border: 1px solid #4f9cff;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-blue-black-cta-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-blue-black-cta-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-blue-black-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-blue-cta-round-button {
    background-color: transparent;
    border: 1px solid #4f9cff;
    color: #4f9cff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-blue-cta-round-button:hover {
    text-decoration: none;
    color: #4f9cff;
}
.transparent-blue-cta-round-button:focus {
    text-decoration: none;
    color: #4f9cff;
    outline: none;
}
.transparent-blue-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-core-blue-cta-round-button {
    background-color: transparent;
    border: 1px solid #539eff;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-core-blue-cta-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.transparent-core-blue-cta-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.transparent-core-blue-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-dark-blue-cta-round-button {
    background-color: transparent;
    border: 1px solid #082f57;
    color: #082f57;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-dark-blue-cta-round-button:hover {
    text-decoration: none;
    color: #082f57;
}
.transparent-dark-blue-cta-round-button:focus {
    text-decoration: none;
    color: #082f57;
    outline: none;
}
.transparent-dark-blue-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-green-cta-round-button {
    background-color: transparent;
    border: 1px solid #2acd85;
    color: #2acd85;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-green-cta-round-button:hover {
    text-decoration: none;
    color: #2acd85;
}
.transparent-green-cta-round-button:focus {
    text-decoration: none;
    color: #2acd85;
    outline: none;
}
.transparent-green-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-grey-cta-round-button {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    color: #bdbdbd;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-grey-cta-round-button:hover {
    text-decoration: none;
    color: #bdbdbd;
}
.transparent-grey-cta-round-button:focus {
    text-decoration: none;
    color: #bdbdbd;
    outline: none;
}
.transparent-grey-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-grey-black-cta-round-button {
    background-color: transparent;
    border: 1px solid #d6d6d5;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-grey-black-cta-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-grey-black-cta-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-grey-black-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-light-grey-cta-round-button {
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: #dfdfdf;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-light-grey-cta-round-button:hover {
    text-decoration: none;
    color: #dfdfdf;
}
.transparent-light-grey-cta-round-button:focus {
    text-decoration: none;
    color: #dfdfdf;
    outline: none;
}
.transparent-light-grey-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-light-grey-black-cta-round-button {
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-light-grey-black-cta-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.transparent-light-grey-black-cta-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.transparent-light-grey-black-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-red-cta-round-button {
    background-color: transparent;
    border: 1px solid #ff5050;
    color: #ff5050;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-red-cta-round-button:hover {
    text-decoration: none;
    color: #ff5050;
}
.transparent-red-cta-round-button:focus {
    text-decoration: none;
    color: #ff5050;
    outline: none;
}
.transparent-red-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-orange-cta-round-button {
    background-color: transparent;
    border: 1px solid #fd963b;
    color: #fd963b;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-orange-cta-round-button:hover {
    text-decoration: none;
    color: #fd963b;
}
.transparent-orange-cta-round-button:focus {
    text-decoration: none;
    color: #fd963b;
    outline: none;
}
.transparent-orange-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-black-cta-round-button {
    background-color: transparent;
    border: 1px solid #5a5a5a;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-black-cta-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-black-cta-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-black-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-yellow-cta-round-button {
    background-color: transparent;
    border: 1px solid #fdb249;
    color: #fdb249;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-yellow-cta-round-button:hover {
    text-decoration: none;
    color: #fdb249;
}
.transparent-yellow-cta-round-button:focus {
    text-decoration: none;
    color: #fdb249;
    outline: none;
}
.transparent-yellow-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.white-light-grey-black-cta-round-button {
    background-color: #fff;
    border: 1px solid #dfdfdf;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.white-light-grey-black-cta-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.white-light-grey-black-cta-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.white-light-grey-black-cta-round-button:disabled {
    cursor: default;
}

.transparent-black-red-cta-round-button {
    background-color: transparent;
    border: 1px solid #ff5050;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-black-red-cta-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.transparent-black-red-cta-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.transparent-black-red-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-core-blue-grey-cta-round-button {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.transparent-core-blue-grey-cta-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.transparent-core-blue-grey-cta-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.transparent-core-blue-grey-cta-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-blue-white-cta-round-button {
    background-color: #fff;
    border: 1px solid #fff;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 21px;
    padding: 12px 45px;
}
.core-blue-white-cta-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.core-blue-white-cta-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.core-blue-white-cta-round-button:disabled {
    cursor: default;
}

.green-med-round-button {
    background-color: #2acd85;
    border: 1px solid #2acd85;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.green-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #26b877;
    border: 1px solid #26b877;
}
.green-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.green-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-green-med-round-button {
    background-color: #36cc87;
    border: 1px solid #36cc87;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.core-green-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #2fb97a;
    border: 1px solid #2fb97a;
}
.core-green-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.core-green-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.btn-green-med-round-button {
    background-color: #57d693;
    border: 1px solid #57d693;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.btn-green-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #43d186;
    border: 1px solid #43d186;
}
.btn-green-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.btn-green-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.blue-med-round-button {
    background-color: #4f9cff;
    border: 1px solid #4f9cff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.blue-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #368eff;
    border: 1px solid #368eff;
}
.blue-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.blue-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-blue-med-round-button {
    background-color: #539eff;
    border: 1px solid #539eff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.core-blue-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #3a90ff;
    border: 1px solid #3a90ff;
}
.core-blue-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.core-blue-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.red-med-round-button {
    background-color: #ff5050;
    border: 1px solid #ff5050;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.red-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #ff3737;
    border: 1px solid #ff3737;
}
.red-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.red-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.orange-med-round-button {
    background-color: #fd963b;
    border: 1px solid #fd963b;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.orange-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #fd8822;
    border: 1px solid #fd8822;
}
.orange-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.orange-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.dark-blue-med-round-button {
    background-color: #082f57;
    border: 1px solid #082f57;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.dark-blue-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #062240;
    border: 1px solid #062240;
}
.dark-blue-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.dark-blue-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.disabled-med-round-button {
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.disabled-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #b0b0b0;
    border: 1px solid #b0b0b0;
}
.disabled-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.disabled-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.light-grey-med-round-button {
    background-color: #dfdfdf;
    border: 1px solid #dfdfdf;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.light-grey-med-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.light-grey-med-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.light-grey-med-round-button:disabled {
    cursor: default;
}

.black-med-round-button {
    background-color: #5a5a5a;
    border: 1px solid #5a5a5a;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.black-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #4d4d4d;
    border: 1px solid #4d4d4d;
}
.black-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.black-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-white-med-round-button {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-white-med-round-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
}
.transparent-white-med-round-button:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}
.transparent-white-med-round-button:disabled {
    cursor: default;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-blue-black-med-round-button {
    background-color: transparent;
    border: 1px solid #4f9cff;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-blue-black-med-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-blue-black-med-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-blue-black-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-blue-med-round-button {
    background-color: transparent;
    border: 1px solid #4f9cff;
    color: #4f9cff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-blue-med-round-button:hover {
    text-decoration: none;
    color: #4f9cff;
}
.transparent-blue-med-round-button:focus {
    text-decoration: none;
    color: #4f9cff;
    outline: none;
}
.transparent-blue-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-core-blue-med-round-button {
    background-color: transparent;
    border: 1px solid #539eff;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-core-blue-med-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.transparent-core-blue-med-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.transparent-core-blue-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-dark-blue-med-round-button {
    background-color: transparent;
    border: 1px solid #082f57;
    color: #082f57;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-dark-blue-med-round-button:hover {
    text-decoration: none;
    color: #082f57;
}
.transparent-dark-blue-med-round-button:focus {
    text-decoration: none;
    color: #082f57;
    outline: none;
}
.transparent-dark-blue-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-green-med-round-button {
    background-color: transparent;
    border: 1px solid #2acd85;
    color: #2acd85;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-green-med-round-button:hover {
    text-decoration: none;
    color: #2acd85;
}
.transparent-green-med-round-button:focus {
    text-decoration: none;
    color: #2acd85;
    outline: none;
}
.transparent-green-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-grey-med-round-button {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    color: #bdbdbd;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-grey-med-round-button:hover {
    text-decoration: none;
    color: #bdbdbd;
}
.transparent-grey-med-round-button:focus {
    text-decoration: none;
    color: #bdbdbd;
    outline: none;
}
.transparent-grey-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-grey-black-med-round-button {
    background-color: transparent;
    border: 1px solid #d6d6d5;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-grey-black-med-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-grey-black-med-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-grey-black-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-light-grey-med-round-button {
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: #dfdfdf;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-light-grey-med-round-button:hover {
    text-decoration: none;
    color: #dfdfdf;
}
.transparent-light-grey-med-round-button:focus {
    text-decoration: none;
    color: #dfdfdf;
    outline: none;
}
.transparent-light-grey-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-light-grey-black-med-round-button {
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-light-grey-black-med-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.transparent-light-grey-black-med-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.transparent-light-grey-black-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-red-med-round-button {
    background-color: transparent;
    border: 1px solid #ff5050;
    color: #ff5050;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-red-med-round-button:hover {
    text-decoration: none;
    color: #ff5050;
}
.transparent-red-med-round-button:focus {
    text-decoration: none;
    color: #ff5050;
    outline: none;
}
.transparent-red-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-orange-med-round-button {
    background-color: transparent;
    border: 1px solid #fd963b;
    color: #fd963b;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-orange-med-round-button:hover {
    text-decoration: none;
    color: #fd963b;
}
.transparent-orange-med-round-button:focus {
    text-decoration: none;
    color: #fd963b;
    outline: none;
}
.transparent-orange-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-black-med-round-button {
    background-color: transparent;
    border: 1px solid #5a5a5a;
    color: #5a5a5a;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-black-med-round-button:hover {
    text-decoration: none;
    color: #5a5a5a;
}
.transparent-black-med-round-button:focus {
    text-decoration: none;
    color: #5a5a5a;
    outline: none;
}
.transparent-black-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-yellow-med-round-button {
    background-color: transparent;
    border: 1px solid #fdb249;
    color: #fdb249;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-yellow-med-round-button:hover {
    text-decoration: none;
    color: #fdb249;
}
.transparent-yellow-med-round-button:focus {
    text-decoration: none;
    color: #fdb249;
    outline: none;
}
.transparent-yellow-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.white-light-grey-black-med-round-button {
    background-color: #fff;
    border: 1px solid #dfdfdf;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.white-light-grey-black-med-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.white-light-grey-black-med-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.white-light-grey-black-med-round-button:disabled {
    cursor: default;
}

.transparent-black-red-med-round-button {
    background-color: transparent;
    border: 1px solid #ff5050;
    color: #484848;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-black-red-med-round-button:hover {
    text-decoration: none;
    color: #484848;
}
.transparent-black-red-med-round-button:focus {
    text-decoration: none;
    color: #484848;
    outline: none;
}
.transparent-black-red-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.transparent-core-blue-grey-med-round-button {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.transparent-core-blue-grey-med-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.transparent-core-blue-grey-med-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.transparent-core-blue-grey-med-round-button:disabled {
    cursor: default;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
}

.core-blue-white-med-round-button {
    background-color: #fff;
    border: 1px solid #fff;
    color: #539eff;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}
.core-blue-white-med-round-button:hover {
    text-decoration: none;
    color: #539eff;
}
.core-blue-white-med-round-button:focus {
    text-decoration: none;
    color: #539eff;
    outline: none;
}
.core-blue-white-med-round-button:disabled {
    cursor: default;
}

.btn-primary {
    background-color: #005bc0;
    border: 1px solid #005bc0;
    color: #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-primary:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #004fa7;
    border: 1px solid #004fa7;
}
.btn-primary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-secondary {
    background-color: #ffc257;
    border: 1px solid #ffc257;
    color: #1b1c1d;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-secondary:not(:disabled):hover {
    text-decoration: none;
    color: #1b1c1d;
    background-color: #ffb84b;
    border: 1px solid #ffb84b;
}
.btn-secondary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-tertiary {
    background-color: #1b85eb;
    border: 1px solid #1b85eb;
    color: #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-tertiary:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #1677e3;
    border: 1px solid #1677e3;
}
.btn-tertiary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-alert {
    background-color: #ee483b;
    border: 1px solid #ee483b;
    color: #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-alert:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #ea3d31;
    border: 1px solid #ea3d31;
}
.btn-alert:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-success {
    background-color: #2dc8a8;
    border: 1px solid #2dc8a8;
    color: #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-success:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #25bf9c;
    border: 1px solid #25bf9c;
}
.btn-success:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-grey {
    background-color: #bababb;
    border: 1px solid #bababb;
    color: #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-grey:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #b4b4b4;
    border: 1px solid #b4b4b4;
}
.btn-grey:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-navy {
    background-color: #011e69;
    border: 1px solid #011e69;
    color: #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-navy:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #011750;
    border: 1px solid #011750;
}
.btn-navy:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-stroked-primary {
    background-color: transparent;
    border: 1px solid #005bc0;
    color: #005bc0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-stroked-primary:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #005bc0;
    border: 1px solid #005bc0;
}
.btn-stroked-primary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-stroked-secondary {
    background-color: transparent;
    border: 1px solid #ffc257;
    color: #ffc257;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-stroked-secondary:not(:disabled):hover {
    text-decoration: none;
    color: #005bc0;
    background-color: #ffc257;
    border: 1px solid #ffc257;
}
.btn-stroked-secondary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-stroked-tertiary {
    background-color: transparent;
    border: 1px solid #1b85eb;
    color: #1b85eb;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-stroked-tertiary:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #1b85eb;
    border: 1px solid #1b85eb;
}
.btn-stroked-tertiary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-stroked-alert {
    background-color: transparent;
    border: 1px solid #ee483b;
    color: #ee483b;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-stroked-alert:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #ee483b;
    border: 1px solid #ee483b;
}
.btn-stroked-alert:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-stroked-success {
    background-color: transparent;
    border: 1px solid #2dc8a8;
    color: #2dc8a8;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-stroked-success:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #2dc8a8;
    border: 1px solid #2dc8a8;
}
.btn-stroked-success:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-stroked-info {
    background-color: transparent;
    border: 1px solid #5f6060;
    color: #5f6060;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-stroked-info:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #5f6060;
    border: 1px solid #5f6060;
}
.btn-stroked-info:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-stroked-grey {
    background-color: transparent;
    border: 1px solid #bababb;
    color: #bababb;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    will-change: color, background-color;
}
.btn-stroked-grey:not(:disabled):hover {
    text-decoration: none;
    color: #fff;
    background-color: #bababb;
    border: 1px solid #bababb;
}
.btn-stroked-grey:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-jumbo {
    font-size: 18px;
    padding: 16px 93px;
    letter-spacing: 1.2px;
    text-align: center;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
    font-family: "Open Sans";
}
.btn-jumbo:focus {
    outline: none;
}

.btn-lg {
    font-size: 15px;
    padding: 14px 43px;
    letter-spacing: 1px;
    text-align: center;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
    font-family: "Open Sans";
}
.btn-lg:focus {
    outline: none;
}

.btn-md {
    font-size: 14px;
    padding: 9px 28px;
    letter-spacing: 0.93px;
    text-align: center;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
    font-family: "Open Sans";
}
.btn-md:focus {
    outline: none;
}

.btn-sm {
    font-size: 13px;
    padding: 8px 17px;
    letter-spacing: 0.8px;
    text-align: center;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
    font-family: "Open Sans";
}
.btn-sm:focus {
    outline: none;
}

.btn-xs {
    font-size: 11px;
    padding: 6px 15px;
    letter-spacing: 0.8px;
    text-align: center;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
    font-family: "Open Sans";
}
.btn-xs:focus {
    outline: none;
}

.transparent-button {
    background: transparent;
    border: none;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
}

/* * * * */
/* .btn-link styles */
/* Used when we need a <button> element to look like an <a> element */
.btn-link {
    text-decoration: underline;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    color: #585c5f;
}
.btn-link:hover {
    color: #1b1c1d;
}
