/* OrgTagsInput styles */
/* NOTE - these styles were taken and adapted from the ReactTags GitHub example */
/* Doc: https://github.com/i-like-robots/react-tags/blob/master/example/styles.css */
.tagsInput {
    position: relative;
    padding-top: 4px;
    /* clicking anywhere will focus the input */
    cursor: text;
}

.selectedTag {
    border: 1px solid #979797;
    padding: 3px 5px;
    margin-bottom: 4px;
}

/* delete icon style */

.selectedTag:after {
    content: "\2715";
    font-size: 9px;
    background: #e8e8e8;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    line-height: 13px;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 5px;
}

.tagsSearch {
    /* match tag layout. +1px compensates tag border */
    padding: 4px 5px;
    margin-bottom: 4px;
    /* prevent autoresize overflowing the container */
    max-width: 100%;
}

.tagsSearchInput {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
}

.tagsSuggestions {
    position: absolute;
    top: 100%;
    left: 0;
}

.tagsSuggestions ul {
    /* top part of <ul /> overlaps bottom part of main input and hides its rounded corners */
    margin: -4px -1px 0;
    padding: 4px 0 0;
    list-style: none;
    background: #fff;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.tagsSuggestions li {
    padding: 10px;
    border-top: 1px solid #e8e8e8;
}

.tagsSuggestions li:hover {
    cursor: pointer;
    background: #f9f9f9;
}

.tagsSuggestions li:last-child {
    border-radius: 0 0 4px 4px;
}

.tagsSuggestions li mark {
    background: none;
}

.tagsSuggestionActive {
    background: #f5f6f9;
}

.tagsSuggestionDisabled {
    background: #fff !important;
    cursor: default !important;
}

/* OrgTagsInputLoader */
.tagsInputLoader {
    min-height: 36px;
}
