.pill {
    height: 30px;
    line-height: 16px;
}

.pill:hover {
    text-decoration: none;
}

.filterValue {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
