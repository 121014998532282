/* Margin + Padding Spacing Utility CSS */
/* TODO - phase these classes out in favor of TailwindCSS */
.m-0-px {
    margin: 0 !important;
}

.mt-0-px,
.my-0-px {
    margin-top: 0 !important;
}

.mr-0-px,
.mx-0-px {
    margin-right: 0 !important;
}

.mb-0-px,
.my-0-px {
    margin-bottom: 0 !important;
}

.ml-0-px,
.mx-0-px {
    margin-left: 0 !important;
}

.m-5-px {
    margin: 5px !important;
}

.mt-5-px,
.my-5-px {
    margin-top: 5px !important;
}

.mr-5-px,
.mx-5-px {
    margin-right: 5px !important;
}

.mb-5-px,
.my-5-px {
    margin-bottom: 5px !important;
}

.ml-5-px,
.mx-5-px {
    margin-left: 5px !important;
}

.m-8-px {
    margin: 8px !important;
}

.mt-8-px,
.my-8-px {
    margin-top: 8px !important;
}

.mr-8-px,
.mx-8-px {
    margin-right: 8px !important;
}

.mb-8-px,
.my-8-px {
    margin-bottom: 8px !important;
}

.ml-8-px,
.mx-8-px {
    margin-left: 8px !important;
}

.m-10-px {
    margin: 10px !important;
}

.mt-10-px,
.my-10-px {
    margin-top: 10px !important;
}

.mr-10-px,
.mx-10-px {
    margin-right: 10px !important;
}

.mb-10-px,
.my-10-px {
    margin-bottom: 10px !important;
}

.ml-10-px,
.mx-10-px {
    margin-left: 10px !important;
}

.m-12-px {
    margin: 12px !important;
}

.mt-12-px,
.my-12-px {
    margin-top: 12px !important;
}

.mr-12-px,
.mx-12-px {
    margin-right: 12px !important;
}

.mb-12-px,
.my-12-px {
    margin-bottom: 12px !important;
}

.ml-12-px,
.mx-12-px {
    margin-left: 12px !important;
}

.m-15-px {
    margin: 15px !important;
}

.mt-15-px,
.my-15-px {
    margin-top: 15px !important;
}

.mr-15-px,
.mx-15-px {
    margin-right: 15px !important;
}

.mb-15-px,
.my-15-px {
    margin-bottom: 15px !important;
}

.ml-15-px,
.mx-15-px {
    margin-left: 15px !important;
}

.m-20-px {
    margin: 20px !important;
}

.mt-20-px,
.my-20-px {
    margin-top: 20px !important;
}

.mr-20-px,
.mx-20-px {
    margin-right: 20px !important;
}

.mb-20-px,
.my-20-px {
    margin-bottom: 20px !important;
}

.ml-20-px,
.mx-20-px {
    margin-left: 20px !important;
}

.m-25-px {
    margin: 25px !important;
}

.mt-25-px,
.my-25-px {
    margin-top: 25px !important;
}

.mr-25-px,
.mx-25-px {
    margin-right: 25px !important;
}

.mb-25-px,
.my-25-px {
    margin-bottom: 25px !important;
}

.ml-25-px,
.mx-25-px {
    margin-left: 25px !important;
}

.m-30-px {
    margin: 30px !important;
}

.mt-30-px,
.my-30-px {
    margin-top: 30px !important;
}

.mr-30-px,
.mx-30-px {
    margin-right: 30px !important;
}

.mb-30-px,
.my-30-px {
    margin-bottom: 30px !important;
}

.ml-30-px,
.mx-30-px {
    margin-left: 30px !important;
}

.m-35-px {
    margin: 35px !important;
}

.mt-35-px,
.my-35-px {
    margin-top: 35px !important;
}

.mr-35-px,
.mx-35-px {
    margin-right: 35px !important;
}

.mb-35-px,
.my-35-px {
    margin-bottom: 35px !important;
}

.ml-35-px,
.mx-35-px {
    margin-left: 35px !important;
}

.m-40-px {
    margin: 40px !important;
}

.mt-40-px,
.my-40-px {
    margin-top: 40px !important;
}

.mr-40-px,
.mx-40-px {
    margin-right: 40px !important;
}

.mb-40-px,
.my-40-px {
    margin-bottom: 40px !important;
}

.ml-40-px,
.mx-40-px {
    margin-left: 40px !important;
}

.m-50-px {
    margin: 50px !important;
}

.mt-50-px,
.my-50-px {
    margin-top: 50px !important;
}

.mr-50-px,
.mx-50-px {
    margin-right: 50px !important;
}

.mb-50-px,
.my-50-px {
    margin-bottom: 50px !important;
}

.ml-50-px,
.mx-50-px {
    margin-left: 50px !important;
}

.m-60-px {
    margin: 60px !important;
}

.mt-60-px,
.my-60-px {
    margin-top: 60px !important;
}

.mr-60-px,
.mx-60-px {
    margin-right: 60px !important;
}

.mb-60-px,
.my-60-px {
    margin-bottom: 60px !important;
}

.ml-60-px,
.mx-60-px {
    margin-left: 60px !important;
}

.m-75-px {
    margin: 75px !important;
}

.mt-75-px,
.my-75-px {
    margin-top: 75px !important;
}

.mr-75-px,
.mx-75-px {
    margin-right: 75px !important;
}

.mb-75-px,
.my-75-px {
    margin-bottom: 75px !important;
}

.ml-75-px,
.mx-75-px {
    margin-left: 75px !important;
}

.m-100-px {
    margin: 100px !important;
}

.mt-100-px,
.my-100-px {
    margin-top: 100px !important;
}

.mr-100-px,
.mx-100-px {
    margin-right: 100px !important;
}

.mb-100-px,
.my-100-px {
    margin-bottom: 100px !important;
}

.ml-100-px,
.mx-100-px {
    margin-left: 100px !important;
}

.p-0-px {
    padding: 0 !important;
}

.pt-0-px,
.py-0-px {
    padding-top: 0 !important;
}

.pr-0-px,
.px-0-px {
    padding-right: 0 !important;
}

.pb-0-px,
.py-0-px {
    padding-bottom: 0 !important;
}

.pl-0-px,
.px-0-px {
    padding-left: 0 !important;
}

.p-5-px {
    padding: 5px !important;
}

.pt-5-px,
.py-5-px {
    padding-top: 5px !important;
}

.pr-5-px,
.px-5-px {
    padding-right: 5px !important;
}

.pb-5-px,
.py-5-px {
    padding-bottom: 5px !important;
}

.pl-5-px,
.px-5-px {
    padding-left: 5px !important;
}

.p-8-px {
    padding: 8px !important;
}

.pt-8-px,
.py-8-px {
    padding-top: 8px !important;
}

.pr-8-px,
.px-8-px {
    padding-right: 8px !important;
}

.pb-8-px,
.py-8-px {
    padding-bottom: 8px !important;
}

.pl-8-px,
.px-8-px {
    padding-left: 8px !important;
}

.p-10-px {
    padding: 10px !important;
}

.pt-10-px,
.py-10-px {
    padding-top: 10px !important;
}

.pr-10-px,
.px-10-px {
    padding-right: 10px !important;
}

.pb-10-px,
.py-10-px {
    padding-bottom: 10px !important;
}

.pl-10-px,
.px-10-px {
    padding-left: 10px !important;
}

.p-12-px {
    padding: 12px !important;
}

.pt-12-px,
.py-12-px {
    padding-top: 12px !important;
}

.pr-12-px,
.px-12-px {
    padding-right: 12px !important;
}

.pb-12-px,
.py-12-px {
    padding-bottom: 12px !important;
}

.pl-12-px,
.px-12-px {
    padding-left: 12px !important;
}

.p-15-px {
    padding: 15px !important;
}

.pt-15-px,
.py-15-px {
    padding-top: 15px !important;
}

.pr-15-px,
.px-15-px {
    padding-right: 15px !important;
}

.pb-15-px,
.py-15-px {
    padding-bottom: 15px !important;
}

.pl-15-px,
.px-15-px {
    padding-left: 15px !important;
}

.p-20-px {
    padding: 20px !important;
}

.pt-20-px,
.py-20-px {
    padding-top: 20px !important;
}

.pr-20-px,
.px-20-px {
    padding-right: 20px !important;
}

.pb-20-px,
.py-20-px {
    padding-bottom: 20px !important;
}

.pl-20-px,
.px-20-px {
    padding-left: 20px !important;
}

.p-25-px {
    padding: 25px !important;
}

.pt-25-px,
.py-25-px {
    padding-top: 25px !important;
}

.pr-25-px,
.px-25-px {
    padding-right: 25px !important;
}

.pb-25-px,
.py-25-px {
    padding-bottom: 25px !important;
}

.pl-25-px,
.px-25-px {
    padding-left: 25px !important;
}

.p-30-px {
    padding: 30px !important;
}

.pt-30-px,
.py-30-px {
    padding-top: 30px !important;
}

.pr-30-px,
.px-30-px {
    padding-right: 30px !important;
}

.pb-30-px,
.py-30-px {
    padding-bottom: 30px !important;
}

.pl-30-px,
.px-30-px {
    padding-left: 30px !important;
}

.p-35-px {
    padding: 35px !important;
}

.pt-35-px,
.py-35-px {
    padding-top: 35px !important;
}

.pr-35-px,
.px-35-px {
    padding-right: 35px !important;
}

.pb-35-px,
.py-35-px {
    padding-bottom: 35px !important;
}

.pl-35-px,
.px-35-px {
    padding-left: 35px !important;
}

.p-40-px {
    padding: 40px !important;
}

.pt-40-px,
.py-40-px {
    padding-top: 40px !important;
}

.pr-40-px,
.px-40-px {
    padding-right: 40px !important;
}

.pb-40-px,
.py-40-px {
    padding-bottom: 40px !important;
}

.pl-40-px,
.px-40-px {
    padding-left: 40px !important;
}

.p-50-px {
    padding: 50px !important;
}

.pt-50-px,
.py-50-px {
    padding-top: 50px !important;
}

.pr-50-px,
.px-50-px {
    padding-right: 50px !important;
}

.pb-50-px,
.py-50-px {
    padding-bottom: 50px !important;
}

.pl-50-px,
.px-50-px {
    padding-left: 50px !important;
}

.p-60-px {
    padding: 60px !important;
}

.pt-60-px,
.py-60-px {
    padding-top: 60px !important;
}

.pr-60-px,
.px-60-px {
    padding-right: 60px !important;
}

.pb-60-px,
.py-60-px {
    padding-bottom: 60px !important;
}

.pl-60-px,
.px-60-px {
    padding-left: 60px !important;
}

.p-75-px {
    padding: 75px !important;
}

.pt-75-px,
.py-75-px {
    padding-top: 75px !important;
}

.pr-75-px,
.px-75-px {
    padding-right: 75px !important;
}

.pb-75-px,
.py-75-px {
    padding-bottom: 75px !important;
}

.pl-75-px,
.px-75-px {
    padding-left: 75px !important;
}

.p-100-px {
    padding: 100px !important;
}

.pt-100-px,
.py-100-px {
    padding-top: 100px !important;
}

.pr-100-px,
.px-100-px {
    padding-right: 100px !important;
}

.pb-100-px,
.py-100-px {
    padding-bottom: 100px !important;
}

.pl-100-px,
.px-100-px {
    padding-left: 100px !important;
}
