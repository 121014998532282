.intelloModal {
    position: fixed;
    min-height: 100vh;
    width: 100%;
    overflow: auto;
    top: 0;
    left: 0;
    background-color: rgba(90, 90, 90, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    z-index: 200;
}

.intelloModalBody {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}
.intelloModalWrapper {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d6d6d5;
    box-shadow: 0 0 6px #d6d6d5;
    background-color: #fff;
}
.intelloModalContainer {
    padding: 50px;
    min-height: 250px;
}

/* beneath here is the updated styling of the modal which simplifies the modal rendering and cleans it up a bit */

/* modal renders the modal wrapper causing it to be fixed */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    overflow: auto;
}

/* modalContent contains the content of the modal */
.modalContent {
    overflow: hidden;
    position: relative;
    margin: 20px auto;
    background: #fff;
    border-radius: 5px;
}

/* background is the background of the modal */
.background {
    background-color: rgba(90, 90, 90, 0.3);
    left: 0px;
    top: 0px;
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    content: " ";
}

/* close is the button to close the modal */
.close {
    position: absolute;
    top: 12px;
    right: 12px;
    transform: rotate(45deg);
    -webkit-appearance: button;
    color: inherit;
    z-index: 12;
    background: transparent;
    border: none;
    font-size: 24px;
}
.close:focus {
    outline: none;
}

.closeModalButton {
    padding: 10px 20px 0 0;
    border: none !important;
}
.closeModalButton:hover:enabled {
    color: #5a5a5a;
}

.rightDrawer {
    position: absolute;
    right: 0;
    min-height: 100%;
    margin: 0 auto;
    border-radius: 0;
    width: 600px;
}

.modalCloseButton {
    background: transparent;
    outline: none;
    border: none;
}
.modalCloseButton:hover {
    color: rgba(#005bc0, 0.95);
}
