form {
    display: flex;
    flex-direction: column;
    /* NOTE - only used in shared_modules/search_filter */
}
form .input-wrapper {
    align-self: flex-start;
    display: flex;
    /* the reverse column allows us to always have the input checkbox at the top of the container rather than the bottom (where it's located in the html) */
    flex-direction: column-reverse;
    padding: 10px 0;
    width: 100%;
    position: relative;
}
form .inline {
    display: flex;
    flex-direction: row;
    width: auto;
}
form .inline input,
form .inline label {
    width: auto;
}
form .inline label {
    padding-left: 10px;
}
form input,
form textarea,
form select {
    outline: none;
    font-size: 16px;
    padding: 12px 15px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #dfdfdf;
    font-family: "Roboto", sans-serif;
    color: #484848;
    background-repeat: no-repeat;
    background-position: 97% 50%;
}
form input::-webkit-input-placeholder,
form textarea::-webkit-input-placeholder,
form select::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bdbdbd;
    font-weight: 18px;
    font-size: 16px;
}
form input::-moz-placeholder,
form textarea::-moz-placeholder,
form select::-moz-placeholder {
    /* Firefox 19+ */
    color: #bdbdbd;
    font-weight: 18px;
    font-size: 16px;
}
form input:-ms-input-placeholder,
form textarea:-ms-input-placeholder,
form select:-ms-input-placeholder {
    /* IE 10+ */
    color: #bdbdbd;
    font-weight: 18px;
    font-size: 16px;
}
form input:focus,
form input:hover,
form textarea:focus,
form textarea:hover,
form select:focus,
form select:hover {
    border-color: #4f9cff;
}
form input[type="radio"] {
    margin: 0;
}
form label {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    text-transform: capitalize;
    padding-bottom: 5px;
}
form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #dfdfdf;
    padding: 10px 20px 10px 10px;
    background-color: #fff;
    text-transform: initial;
}
form select option {
    text-transform: capitalize;
}
form .error {
    border: 1px solid #ff5050;
    background-color: rgba(255, 80, 80, 0.05);
    color: #ff5050;
}
form .error::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ff5050;
    font-weight: 18px;
    font-size: 16px;
}
form .error::-moz-placeholder {
    /* Firefox 19+ */
    color: #ff5050;
    font-weight: 18px;
    font-size: 16px;
}
form .error:-ms-input-placeholder {
    /* IE 10+ */
    color: #ff5050;
    font-weight: 18px;
    font-size: 16px;
}
form .error:focus,
form .error:hover {
    border: 1px solid #ff5050;
}
form button[type="submit"] {
    margin-top: 10px;
}

form.underline .error input,
form.underline .error input:focus {
    border-bottom: 1px solid #ff5050;
}

form.underline input,
form.underline textarea {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px;
    border-radius: 0;
}
form.underline input:focus,
form.underline textarea:focus {
    border-bottom: 1px solid #2acd85;
}

/* * * * */
/* Generic Form Input CSS classes */
/* TODO - migrate this to shared_modules/input */
input.form-input {
    overflow: visible;
    outline: none;
    margin: 0;
    line-height: inherit;
    color: #484848;
    box-sizing: border-box;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background: #fff;
    background-repeat: no-repeat;
    background-position: 97% 50%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    width: 100%;
}
input.form-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bdbdbd;
}
input.form-input::-moz-placeholder {
    /* Firefox 19+ */
    color: #bdbdbd;
}
input.form-input:-ms-input-placeholder {
    /* IE 10+ */
    color: #bdbdbd;
}
input.form-input:focus,
input.form-input:hover:not(:disabled) {
    border-color: #4f9cff;
}
input.form-input.form-input-sm {
    font-size: 12px;
    height: 30px;
    padding: 0 10px;
}
input.form-input.form-input-md {
    height: 34px;
    font-size: 14px;
    padding: 12px;
}
input.form-input.form-input-lg {
    font-size: 16px;
    padding: 12px 15px;
}
input.form-input:disabled {
    background-color: #f5f6f9;
    cursor: not-allowed;
}
