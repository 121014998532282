/* * * * */
/* Font Color Classes */
.text-blue {
    color: #539eff !important; /* $coreBlue */
}

.text-green {
    color: #36cc87 !important; /* $coreGreen */
}

.text-red {
    color: #ef2c00 !important; /* $coreRed */
}

.text-yellow {
    color: #fdb249 !important; /* $yellow */
}

/* * * * */
/* Black / White / Grey */
.text-black {
    color: #5a5a5a !important; /* $black */
}

.text-white {
    color: #fff !important; /* $white */
}

.text-grey {
    color: #bdbdbd !important; /* $medium-grey */
}
