.modal {
    background-color: rgba(90, 90, 90, 0.3);
    left: 0px;
    top: 0px;
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.bodyWrapper {
    height: 100%;
    width: 100%;
}

.body {
    width: 570px;
    padding: 50px 30px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #d6d6d5;
    box-shadow: 0 0 6px #d6d6d5;
}

.header {
    color: #0f244d;
    font-family: "Avenir", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
    text-align: center;
    padding-bottom: 5px;
}

.description {
    color: #5a5a5a;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 31px;
    text-align: center;
    padding-bottom: 10px;
}

.valuePropList {
    max-width: 455px;
    margin: 0 auto;
}

.valueProp {
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 31px;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
}

.demoLink {
    max-width: 310px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
}

.billingQ {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 31px;
    text-align: center;
}

.billingLink {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    line-height: 31px;
    text-align: center;
    text-decoration: underline;
    display: block;
}

.dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #5a5a5a;
    display: inline-block;
    margin-right: 10px;
}
