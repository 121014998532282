.fullHeight {
    height: 100vh;
}

/* noScroll is used to prevent the body section from scrolling when the "more filters" section is open */
.noScroll {
    overflow: hidden;
}

.listingWrapper {
    padding: 0 30px 30px;
    max-width: 1100px;
}

.separator {
    width: 100%;
    padding: 30px 15px 10px;
}

.separatorLine {
    border-top: 1px dashed #dfdfdf;
}

.separatorText {
    padding: 0 30px;
    color: #0e234e;
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 700;
}

.transparentTile {
    opacity: 0.65;
}
.transparentTile:hover {
    opacity: 1;
}

.lastSpend {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
}

.spendDate {
    color: #bdbdbd;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
}

.spendRange {
    color: #2acd85;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
}

.spendContainer {
    padding: 0 15px;
    flex: 0 0 14%;
    max-width: 14%;
}

.stateContainer {
    padding: 0 15px;
    flex: 0 0 20%;
    max-width: 20%;
}

/* we are using such a high z-index so that the more filters section overlaps all dropdowns beneath it */
/* we need to set the fixed height because otherwise it is hard to make the additional filters slide out smoothly */
.filterRow {
    /* we need the filter row to be displayed over the more filters section */
    z-index: 6;
    background: #fff;
    height: 68px;
    border-bottom: 1px solid #dfdfdf;
}

.filtersWrapper {
    padding: 0 30px;
    max-width: 1100px;
}

.resetFiltersButton {
    font-family: "Roboto", sans-serif;
    padding-left: 20px;
    white-space: nowrap;
    font-size: 12px;
    text-decoration: underline;
    color: #5a5a5a;
    border: none;
}

.headerWrapper {
    background-color: #fff;
    border-bottom: 1px solid #dfdfdf;
}

.header {
    max-width: 1100px;
    border-bottom: none;
}

.bodyWrapper {
    position: relative;
    /* this one is required so that the status tooltip doesn't cause the horizontal scrolling */
    overflow: hidden;
}

.emptyListWrapper {
    padding: 0 30px 20px;
    max-width: 1100px;
}

.searchWrapper {
    margin-right: 10px;
}

.addAppWrapper {
    padding: 20px 0 20px 20px;
}

.advancedFilterContainer {
    /* all filters have widths equal to that of the date range filter */
    width: 238px;
}

/* NoSpendMessage styles */
.noSpendMessage {
    box-shadow: none;
}

/* MoreFilters Styles */

.moreFilters {
    width: 100%;
    background: #fff;
    z-index: 5;
    max-height: calc(100% - 0px);
    overflow-y: scroll;
}

.moreFiltersSection:not(:first-child) {
    padding-top: 30px;
    border-top: 1px solid #dfdfdf;
}

.moreFiltersCancelButton {
    border: none !important;
}

.moreFiltersSubheader {
    padding-bottom: 30px;
    color: #bdbdbd;
    font: 16px/19px "Roboto", sans-serif;
}

.moreFiltersHeader {
    color: #0e234e;
    font: 18px/21px "Roboto", sans-serif;
    font-weight: bold;
}

/* Filter (component) styles */

.filterComponentWrapper {
    /* we are using this negative margin to compensate the right padding of the filter components */
    margin-right: -15px;
}

.filterName {
    color: #5a5a5a;
    font: 16px/19px "Roboto", sans-serif;
    font-weight: 500;
    padding-right: 75px;
}

.filterWrapper {
    padding-bottom: 20px;
}
