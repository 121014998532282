/* * * * * */
/* RelatedVendorsList styles */
.relatedVendorsListHeader {
    border-bottom: 1px solid #e8e8e8 !important; /* $greyBorder */
    background-color: #fff;
}

.relatedVendorsListItem {
    text-decoration: none !important;
    border-bottom: 1px solid #e8e8e8 !important; /* $greyBorder */
    min-height: 45px;
    background-color: #fff;
}

.relatedVendorsListItem:hover {
    background-color: #f5f6f9; /* $backgroundMain */
}

/* * * * * */
/* RelatedVendorsModal styles */
.modalPage {
    width: 600px !important;
}
