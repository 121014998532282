/* toasterSlideIn slide in slides the toaster in from the bottom of the screen */
@keyframes toasterSlideIn {
    from {
        opacity: 0;
        transform: translateY(120%);
    }
    to {
        transform: translateY(0);
    }
}

/* toasterSlideOut slides the toaster out to the bottom of the screen while shrinking it */
@keyframes toasterSlideOut {
    from {
        transform: scale(1);
        opacity: 1;
        transform: translateY(0);
    }
    to {
        transform: scale(0.9);
        opacity: 0;
        transform: translateY(80%);
    }
}

/* toaster defines the styling so that's sticky at the bottom right of the screen */
.toaster {
    max-width: 650px;
    position: fixed;
    z-index: 1000;
    width: 100%;
    bottom: 30px;
    right: 90px; /* we go 90 pixels from the right so that we don't overlay the intercom modal */
    transition: transform, opacity 250ms cubic-bezier(0, 0, 0.2, 1);
}
.toaster.add {
    opacity: 0 !important;
}
/* NOTE - we set the data properties inside the component from the state of the transition to make the transition easy to work with */
.toaster[data-state="entering"],
.toaster[data-state="entered"] {
    animation: toasterSlideIn 250ms cubic-bezier(0.175, 0.885, 0.32, 1.175) both;
}
.toaster[data-state="exiting"] {
    animation: toasterSlideOut 250ms cubic-bezier(0.4, 0, 1, 1) both;
}
