/* Cursor Utility CSS */
.cursor-help {
    cursor: help !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}
