.toggleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.toggleInput {
    display: none;
}
.toggleInput:checked + label:before {
    border: 1px solid #2acd85;
    transform: translateX(18px);
}
.toggleInput:checked + label:after {
    background-color: #2acd85;
}

.toggle {
    height: 26px;
    width: 42px;
    position: relative;
    cursor: pointer;
}
.toggle:before {
    border-radius: 25px;
    width: 25px;
    height: 25px;
    display: block;
    content: "";
    background-color: #fff;
    border: 1px solid #d6d6d5;
    transition: transform 0.2s ease-in, background-color 0.2s linear;
    position: relative;
    left: -1px;
    top: -1px;
    z-index: 2;
}
.toggle:after {
    border-radius: 15px;
    background-color: #fff;
    width: 42px;
    height: 25px;
    border: 1px solid #d6d6d5;
    position: relative;
    top: -26px;
    display: block;
    content: "";
    transition: transform 0.2s ease-in, background-color 0.2s linear;
    z-index: 1;
}

.sm {
    transform: scale(0.75);
}

.redToggle input {
    display: none;
}
.redToggle input:checked + label:before {
    border: 1px solid #ff5050;
    transform: translateX(18px);
}
.redToggle input:checked + label:after {
    background-color: #ff5050;
}

.labeledToggleWrapper {
    padding: 0 15px;
}

.toggleText {
    font-size: 16px;
    line-height: 19px;
}
