/* Sizing Utility CSS */
.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.w-xxl {
    max-width: 1400px;
}

.intello-max-width {
    max-width: 1100px;
}
