/* WeekInputInline styles */
.weekInputPreview {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
}

.weekInputPreviewActive {
    border-bottom-color: #fff;
    z-index: 30;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.weekInputWrapper {
    transform: translateY(-1px);
    z-index: 20;
}
