.body {
    width: 100%;
}

.fieldTypes {
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
}

.sectionHeader {
    color: #0e234e;
    font: 14px/19px "Avenir", sans-serif;
    font-weight: 700;
    padding: 15px;
}

.fieldType {
    cursor: pointer;
    background: none;
    padding: 15px;
    border: none;
    outline: none;
    border-bottom: 1px solid #e8e8e8;
}

.fieldType:first-of-type {
    border-top: 1px solid #e8e8e8;
}

.fieldType:last-child {
    border-bottom: none;
}

.selectedFieldType {
    background: #005bc0;
}

.selectedFieldType .fieldTypeIcon g {
    fill: #fff;
}
.selectedFieldType .fieldTypeIcon path {
    fill: #fff;
}

.selectedFieldType .fieldTypeName {
    color: #fff;
}

.fieldTypeIcon {
    width: 55px;
    /* used fixed width here instead of bootstrap grid columns because it looks better on wider screens */
    padding: 0;
}

.fieldTypeName {
    text-align: left;
    color: #5a5a5a;
    font: 16px/32px "Roboto", sans-serif;
}

.build {
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
}

.fieldValue {
    font: 16px/32px "Roboto", sans-serif;
    border-bottom: 1px solid #e8e8e8;
    padding: 15px;
}

.fieldValueInput {
    padding: 0;
    border: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.deleteFieldValueButton {
    border: none;
    outline: none;
    padding: 0 5px;
    background: transparent;
    opacity: 0.2;
    transform: rotate(45deg);
    font-size: 24px;
}

.addNewFieldValueButtonWrapper {
    padding: 15px;
}

.addNewFieldValueButton {
    border: none;
    outline: none;
    padding: 0;
    background: transparent;
    color: #005bc0;
    font: 16px/32px "Avenir", sans-serif;
    font-weight: 700;
    text-align: left;
}

.errorMessage {
    color: #ff5050;
    padding: 15px 15px 0;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
}

.buildFormSubmitButtonWrapper {
    padding-top: 5px;
}

.preview {
    border-top: 1px solid #e8e8e8;
}

.previewLabel {
    padding-right: 20px;
    color: #5a5a5a;
    font: 14px/16px "Roboto", sans-serif;
    /* if the label is empty, it should still have this height to keep the input fields aligned */
    min-height: 21px;
    font-weight: 500;
}

.singleRowPreviewLabel {
    /* we reset min-height in single row mode to keep the label vertically aligned with the field */
    min-height: 0;
}

.dropdownButton {
    border: none;
    padding: 12px 15px;
    /* thebutton should fill the dropdown list */
    width: 100%;
    /* this is needed so that the active item appears correctly */
    background: transparent;
}

.dropdownButton:active,
.dropdownButton:focus {
    outline: none;
}

.empty {
    padding-top: 148px;
    /* this was taken directly from invision */
    text-align: center;
    color: #bdbdbd;
    font: 16px/19px "Roboto", sans-serif;
}

/* errorAlertWrapper wraps the error alert displayed when there was an error when loading the page */
.errorAlertWrapper {
    padding: 15px 10px;
}
