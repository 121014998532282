* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto; }

*:before,
*:after {
  box-sizing: border-box; }

/* Body reset */
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px; }

/* Reset fonts for relevant elements */
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

nav {
  margin-bottom: 0;
  padding-left: 0; }

button {
  cursor: pointer; }

/* Figures */
/* We reset this here because previously Normalize had no `figure` margins. This */
/* ensures we don't break anyone's use of the element. */
figure {
  margin: 0; }

/* Images */
img {
  vertical-align: middle; }

/*   above is bootstrap specific styles */
html,
body {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  color: #5a5a5a;
  font-family: "Roboto", sans-serif;
  font-weight: 400; }

ul {
  padding: 0;
  margin: 0; }
  ul .blue-bullet {
    padding-left: 15px;
    position: relative;
    margin-left: 8px; }
    ul .blue-bullet:before {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #67b6f2;
      content: " ";
      position: absolute;
      top: 7px;
      left: 0; }

li {
  list-style: none;
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

a,
p {
  margin: 0; }

a {
  color: #005bc0;
  text-decoration: none; }
  a:hover {
    color: #005bc0;
    text-decoration: underline; }
  a:focus {
    outline: none; }

.blue {
  color: #4f9cff; }

.yellow {
  color: #fdb249; }

.green {
  color: #2acd85; }

.red {
  color: #ff5050; }

.navy {
  color: #11264b; }

.black {
  color: #5a5a5a; }

.number {
  font-size: 32px;
  font-family: "Lato", sans-serif;
  font-weight: 300; }

/* NOTE - this exists because the `tw-no-underline` CSS rule doesn't override the default a:hover style in this file */
.no-underline {
  text-decoration: none !important; }
