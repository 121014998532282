.dashboardNav {
    /* this was taken directly from invision */
    width: 90px;
    background: #011e69;
    min-height: 100vh;
    padding: 5px 0 20px;
}

.logoWrapper {
    padding: 10px 0 30px;
}

/* navLink */
.navLink {
    background: #011e69;
    color: #fff;
    opacity: 0.7;
    text-transform: uppercase;
    padding: 10px;
    /* we don't have a constant for 11px */
    font-size: 11px;
}
.navLink svg {
    transition: transform 240ms ease-in-out(0.18, 1.4, 0.4, 1);
}
.navLink svg g {
    fill: #fff;
}
.navLink svg path {
    fill: #fff;
}

/* navLink hover */
.navLink:hover {
    background: #011e69;
    color: #fff;
    opacity: 1;
    text-decoration: none;
}
.navLink:hover svg {
    transform: scale(1.15);
}
.navLink:hover svg g {
    fill: #fff;
}
.navLink:hover svg path {
    fill: #fff;
}

/* navLink active */
.navLinkActive {
    background: #011e69;
    color: #ffb84b !important; /* Ensure color doesn't change on-hover */
    opacity: 1;
}
.navLinkActive svg g {
    fill: #ffb84b !important; /* Ensure color doesn't change on-hover */
}
.navLinkActive svg path {
    fill: #ffb84b !important; /* Ensure color doesn't change on-hover */
}

.navLinkIcon {
    padding-bottom: 5px;
}

.dashboardWrapper {
    max-width: 1100px;
}

.dashboardWrapperFullWidth {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.countDownWrapper {
    border-bottom: 1px solid #e8e8e8;
    z-index: 10;
}

.countDownLink {
    text-decoration: underline;
}
.countDownLink:hover {
    color: #5f6060;
}
