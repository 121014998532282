/* * * * */
/* FilterListWhereBadge styles */
.filterListWhereBadge {
    position: absolute;
    margin-top: -4px !important;
    letter-spacing: 0.5px;
}

/* * * * */
/* FilterListExpressionBadge */
.andExpressionBadge {
    letter-spacing: 0.5px;
    border-left: 2px solid #005bc0; /* $primary */
}
.andExpressionBadgeContent {
    margin-left: -18px; /* NOTE - this is hard-coded to center the badge above the left-border */
}

.orExpressionBadge {
    letter-spacing: 0.5px;
    border-left: 2px solid #1b85eb; /* $tertiary */
}
.orExpressionBadgeContent {
    margin-left: -14px; /* NOTE - this is hard-coded to center the badge above the left-border */
}

/* * * * */
/* FilterListContainer styles */
.filterListContainerSourceBadge {
    position: absolute;
    right: 0;
    margin-top: -28px;
    margin-right: 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.filterListContainerAndBadge {
    margin-bottom: -11px;
}

/* * * * */
/* FilterListItem styles */
.filterListItemDragging {
    opacity: 0.4;
}

.filterListItemInactive {
    opacity: 0.4;
}

/* * * * */
/* FilterListItemSummary styles */
.circularBadge {
    border-radius: 17px; /* Taken from InVision */
    min-width: 45px;
    text-align: center;
}

/* * * * */
/* FilterForm styles */
.formLabel {
    color: #5a5a5a; /* $black */
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    display: block;
    padding: 0;
}

.formGroup {
    margin-bottom: 20px;
}

.formGroup .formLabel {
    margin-bottom: 10px;
}

/* * * * */
/* FilterListItemDragHandle */
.dragHandleWrapper {
    cursor: grab;
    color: #dfdfdf; /* $lighter-grey */
    transition: color 200ms ease-in-out;
}
.dragHandleWrapper:hover {
    color: #bdbdbd; /* $medium-grey */
}

.dragHandlePlaceholder {
    visibility: hidden;
}

/* * * * */
/* DynamicFilterValueInput styles */
/* Prevents Sign dropdown from shifting horizontally when switching between `+` and `-` */
.dynamicFilterSignDropdown {
    min-width: 70px;
}

/* * * * */
/* FilterListEmptyState styles */
.filterListEmptyState {
    min-height: 166px;
}

/* * * * */
/* AdvancedFilterEditor - SuggestedFilters styles */
.suggestedFilterHeader {
    font-style: italic;
}

/* * * * */
/* PossibleFilterContent styles */
.possibleFilterTooltip {
    max-width: 300px;
    text-align: center;
}

.possibleFilterTooltipContent {
    min-width: 150px;
}
