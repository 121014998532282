/* frame is the frame body */
.frame {
    max-width: 955px; /* $publicMainWidth; */
    margin: 0 auto;
    padding: 91px 0 180px;
    /* these values were taken from invision */
    text-align: center;
    color: #5a5a5a;
}

/* title is the styling to apply to the title of the frame */
.title {
    margin: 0;
    color: #1b85eb; /* $tertiary */
    font: 36px "Avenir", sans-serif;
    line-height: 49px;
    font-weight: 700;
    padding-bottom: 10px;
}

/* description is the description of the frame */
.description {
    font: 18px "Avenir", sans-serif;
    line-height: 25px;
    font-weight: 700;
    margin: 0 auto;
    max-width: 650px;
}
