/* DateInputPreview styles */

.dateInputPreview {
    outline: none;
    border: 1px solid #dfdfdf;
    position: relative;
    border-radius: 4px;
}
.dateInputPreview.dateInputPreviewActive {
    border-bottom-color: #fff;
    z-index: 30;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.dateInputWrapper {
    min-width: 510px;
    position: absolute;
    border: 1px solid #dfdfdf;
    transform: translateY(-1px);
    z-index: 20;
}

/* CustomDateRangeInput styles */

.customDateRangeNumberInput {
    max-width: 55px;
}

.customDateRangeRadioButton {
    max-width: 60px;
}

/* NOTE - these styles ensure that the arrows present on the input[type=number] are hidden
Doc: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
.customDateRange input::-webkit-inner-spin-button,
.customDateRange input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.customDateRange input[type="number"] {
    -moz-appearance: textfield;
}

/* RelativeDateInput + FixedDateInput + UndefinedDateInput styles */
.inputContainer {
    min-height: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
