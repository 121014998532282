.filterWrapper {
    min-width: 140px;
    padding: 0 10px 0 0;
}

.filter {
    width: 100%;
    height: 32px;
    word-break: break-word;
    hyphens: auto;
}
.filter:hover {
    text-decoration: none;
    color: #5f6060; /* $greyMid */
}
