@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }
    25% {
        height: 0%;
    }
    50% {
        height: 100%;
    }
    75% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
}

.loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid #11264b;
    top: 50%;
    border-radius: 0.75rem;
    animation: loader 2.5s infinite ease;
}

.loaderInner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #11264b;
    animation: loader-inner 2s infinite ease-in;
}

.loaderWrapper {
    width: 100%;
    text-align: center;
    padding: 50px;
}
