.header {
    color: #0e234e;
    font-family: "Avenir", sans-serif;
    font-size: 12px;
    font-weight: 700;
}

.activeFilters {
    padding: 10px 30px;
}

.pillsContainer {
    padding: 0 15px;
}

.pillWrapper {
    padding: 5px;
}

.resetAll {
    white-space: nowrap;
    text-decoration: underline;
}

.link:hover {
    text-decoration: none;
}

.tooltip {
    white-space: nowrap;
}
