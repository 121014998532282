/* * * * */
/* Dropdown Component */
/* NOTE - these styles were ganked from Bootstrap, modified to match our sizing + brand styles */
.dropdown {
    position: relative;
}

.dropdownMenu {
    position: absolute;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 100px !important;
    padding: 5px 0; /* $smallPadding 0 */
    margin: 0;
    text-align: left;
    list-style: none;
    background-color: #fff; /* $white */
    background-clip: padding-box;
    border: 1px solid #d6d6d5; /* $tileBorder */
    border-radius: 4px; /* $buttonRadius */
}
.dropdownMenuRight {
    right: 0;
    left: auto;
}
.dropdownMenuOpenDown {
    top: 100%;
    bottom: unset;
}
.dropdownMenuOpenUp {
    top: unset;
    bottom: 100%;
}
.dropdownMenuShow {
    display: block;
}

.dropdownItem {
    display: block;
    width: 100%;
    padding: 5px 15px; /* $smallPadding $defaultItemPadding */
    clear: both;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}
