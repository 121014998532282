/* * * * */
/* OrgSavedViewModal styles */
.orgSavedViewModal {
    width: 80%;
    max-width: 1000px;
    min-height: 640px;
}

.orgSavedViewModalChildren {
    border-left: 1px solid #e8e8e8; /* $greyBorder */
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dismissButton {
    letter-spacing: 2px;
    color: #bababb; /* $greyDim */
}
.dismissButton:hover {
    color: #005bc0; /* $primary */
}

/* * * * */
/* OrgSavedView styles */
.makeDefaultCheckBoxLabel {
    outline: none;
}

.vendorLogo {
    max-width: 20px; /* this width was taken directly from invision */
}

/* * * * */
/* SaveFilterButton styles */
.saveFilterButton {
    height: 30px;
    max-height: 30px;
    letter-spacing: normal !important;
    max-width: fit-content;
    /* NOTE - this is only present because the tw-rounded-full CSS class is overridden by the btn-sm CSS. */
    /* This will be removed when we migrate this file to CSS. */
    border-radius: 9999px !important;
}

/* * * * */
/* OrgSavedViewBanner styles */
.editSavedViewButton {
    text-decoration: underline;
}

/* * * * */
/* OrgSavedViewDropdown styles */
.dropdownListWrapperClassName {
    right: 0;
    border: 1px solid #e8e8e8; /* $greyBorder */
    border-top: none;
    background: #fff;
    width: 400px;
    max-width: 400px;
    min-width: 400px;
}

.saveOrgSavedViewButton {
    border: none;
    color: #2dc8a8;
}
.saveOrgSavedViewButton:hover {
    color: #25bf9c;
}

.saveOrgSavedViewButtonBorder {
    border-bottom: 1px solid #e8e8e8; /* $greyBorder */
}

.suggestedFiltersHeader {
    border-top: 1px solid #e8e8e8; /* $greyBorder */
}

.dropdownButton:hover {
    background: #f5f6f9;
    color: #005bc0;
}

.dropdownLink {
    text-decoration: none !important;
    white-space: normal;
}

/* * * * */
/* DropdownToggleButton */
.dropdownToggleButton {
    width: 375px;
    max-width: 375px;
    min-width: 375px;
}

.dropdownToggleButtonContent {
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdownToggleButtonContentInactive {
    width: 375px;
    max-width: 375px;
    min-width: 375px;
}

/* * * * */
/* ConfirmDeleteSavedView styles */
.confirmDeleteSavedViewButton {
    letter-spacing: normal !important;
}

.cancelDeleteSavedViewButton {
    letter-spacing: normal !important;
    color: #5f6060;
    border-color: #5f6060 !important;
}
.cancelDeleteSavedViewButton:hover {
    background: #5f6060 !important;
}
