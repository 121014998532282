.innerDot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
}

.outerDot {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.dot-green {
    background-color: #2acd85; /* $green */
}

.dot-yellow {
    background-color: #fdb249; /* $yellow */
}

.dot-red {
    background-color: #ff5050; /* $red */
}

.dot-navy {
    background-color: #11264b; /* $navy */
}

.dot-grey {
    background-color: #bababb; /* $greyDim */
}
