.name {
    font-size: 24px;
    font-weight: 400;
    color: #5a5a5a;
    word-wrap: break-word;
    line-height: 28px;
}

.category {
    font-size: 14px;
    color: #bdbdbd;
    font-weight: 300;
    font-family: "Avenir", sans-serif;
    line-height: 19px;
}

.tileContent {
    /* bec the tile uses bootstrap columns there' is another 15 pixels of padding within the tile so we have the right + left w/ only 15px here to match the designs
    padding: $medPadding $defaultItemPadding;
    update to use bootstrap classes to remove the margin */
    padding: 20px 15px;
    color: #5a5a5a;
}

.tileContent:hover {
    text-decoration: none;
    color: #5a5a5a;
}
