/* Line Height Utility CSS */
.line-height-16-px {
    line-height: 16px;
}

.line-height-19-px {
    line-height: 19px;
}

.line-height-23-px {
    line-height: 23px;
}

.line-height-24-px {
    line-height: 24px;
}

.line-height-26-px {
    line-height: 26px;
}

.line-height-28-px {
    line-height: 28px;
}

.line-height-33-px {
    line-height: 33px;
}

.line-height-40-px {
    line-height: 40px;
}
